import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { customerPath } from "shared/constants/routes";
import {
  fetchAppointmentSlotsItems,
  rescheduleAppointment,
  rescheduleRemote,
} from "store/actions/chat-actions.js";
import { appDealershipSlugSelector } from "store/selectors/app-selectors";
import { chatIsMobileTechnicianJobCheckinSelector, chatIsPickupJobCheckinSelector, rescheduleSelector } from "store/selectors/chat-selectors";
import Button from "components/common/Button/Button";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrow/left.svg";
import { ReactComponent as IconArrowRight } from "assets/icons/arrow/right.svg";
import TimePicker from "./TimePicker";
import "./picker.scss";
import styles from "./styles.module.scss";

const Reschedule = ({
  datetime,
  slots,
  isPickup,
  isMobileTechnician,
  rescheduleRemote,
  rescheduleAppointment,
  fetchAppointmentSlotsItems,
  dealershipSlug,
}) => {
  const [date, setDate] = useState(moment(datetime).format("YYYY-MM-DD"));
  const [time, setTime] = useState(moment(datetime).format("HH:mm"));
  const [loading, setLoading] = useState(true);
  const [timePickerHeight, setTimePickerHeight] = useState(0);
  const calendarRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    const date = new Date(datetime);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    fetchAppointmentSlotsItems(month, year);

    setTimePickerHeight(calendarRef.current.clientHeight);
  }, []);

  useEffect(() => {
    if (slots.length > 0) {
      setLoading(false);
    }
  }, [slots]);

  const onActiveStartDateChange = ({ action, activeStartDate }) => {
    const date = new Date(activeStartDate);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    setLoading(true);

    fetchAppointmentSlotsItems(month, year);

    setDate(null);
    setTime(null);
    setTimePickerHeight(calendarRef.current.clientHeight);
  };

  const onChange = (date) => {
    setDate(moment(date).format("YYYY-MM-DD"));
    setTime(null);
  };

  const tileDisabled = ({ activeStartDate, date, view }) => {
    if (view === "month") {
      const momentDate = moment(date);
      const dayOfMonth = momentDate.date();
      const fullDateFormat = momentDate.format("YYYY-MM-DD");

      const currentItem = slots[dayOfMonth - 1];

      if (fullDateFormat === currentItem?.full_date) {
        const availableTimeSlots = Object.entries(currentItem.slots).filter(
          (entry) => entry[1].length > 0,
        );

        if (availableTimeSlots.length > 0) {
          return false;
        }
      }

      return true;
    }

    return false;
  };

  const availableTimeSlots = () => {
    let result = [];

    if (date) {
      const day = slots.find(
        (slot) => slot.full_date === moment(date).format("YYYY-MM-DD"),
      );

      result = day
        ? Object.entries(day.slots).reduce((items, entry) => {
            if (entry[1].length > 0) {
              return items.concat(entry[0]);
            }

            return items;
          }, [])
        : [];
    }

    return result;
  };

  const timeSlots = () => {
    let result = [];

    if (date) {
      const day = slots.find(
        (el) => el.full_date === moment(date).format("YYYY-MM-DD"),
      );
      result = day ? Object.keys(day.slots) : [];
    }

    return result;
  };

  const reschedule = () => {
    if (isPickup || isMobileTechnician) {
      rescheduleRemote(date, time)
    }
    rescheduleAppointment(date, time);
    history.push(customerPath(dealershipSlug));
  };

  return (
    <>
      <div className={styles.pageWrapper}>
        <div className={styles.body}>
          <Calendar
            inputRef={calendarRef}
            onActiveStartDateChange={onActiveStartDateChange}
            onChange={onChange}
            value={moment(date, "YYYY-MM-DD").toDate()}
            minDate={new Date()}
            locale="en-US"
            calendarType="US"
            className="customCalendar"
            defaultValue={date}
            minDetail="month"
            prevLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            formatShortWeekday={(locale, date) => moment(date).format("dd")}
            formatMonthYear={(locale, date) =>
              moment(date).format("MMMM, YYYY")
            }
            tileDisabled={tileDisabled}
          />
          <TimePicker
            slots={timeSlots()}
            timeArr={availableTimeSlots()}
            time={time}
            setTime={setTime}
            height={timePickerHeight}
          />
        </div>
        {loading && (
          <div className={styles.loadingContainer}>
            <span>Loading...</span>
          </div>
        )}
      </div>
      <div className={styles.btnWrap}>
        <Button
          color={"#ffffff"}
          bgColor={"linear-gradient(8.15deg, #5430D8 0%, #2B5FC0 100%)"}
          boxShadow={"0px 8px 15px rgba(0, 0, 0, 0.18)"}
          text={"Confirm changes"}
          onClick={() => reschedule()}
          disabled={time === null || date === null}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...rescheduleSelector(state),
  isPickup: chatIsPickupJobCheckinSelector(state),
  isMobileTechnician: chatIsMobileTechnicianJobCheckinSelector(state),
  dealershipSlug: appDealershipSlugSelector(state),
});

export default connect(mapStateToProps, {
  rescheduleRemote,
  rescheduleAppointment,
  fetchAppointmentSlotsItems,
})(Reschedule);
