import React from "react";
import { Route } from "react-router-dom";
import * as routes from "shared/constants/routes";
import Footer from "components/common/Footer/";
import Header from "components/common/Header/";
import ChatPage from "./ChatPage";
import PhoneAuthPage from "./PhoneAuthPage";
import WelcomePage from "./WelcomePage";

const CheckInView = ({ slug }) => (
  <>
    <Route path={routes.checkinWelcomePath(slug)}>
      <WelcomePage />
    </Route>

    <Route path={routes.authPath(slug)}>
      <Header />
      <PhoneAuthPage />
      <Footer isDark />
    </Route>
    <Route path={routes.checkinChatPath(slug)}>
      <Header
        arrowBack={true}
        title={"Check in"}
        arrowBackColor={"#0BCAF9"}
      />

      <ChatPage />
    </Route>
  </>
);

export default CheckInView;
