const convert24HourTo12 = (hour) => hour % 12 || 12;

const amPmValue = (hour) => (hour < 12 ? "am" : "pm");

const hourSlotLabel = (hour) => {
  const minHour = convert24HourTo12(hour);
  const maxHour = convert24HourTo12(hour + 1);
  const amPm = amPmValue(hour + 1);
  return `${minHour} - ${maxHour} ${amPm}`;
};

export const leadingZeroHour = (hour) => (hour < 10 ? `0${hour}` : hour);

export const timeInUSFormat = (time) => {
  const [hour, minutes] = time.split(":");

  return `${leadingZeroHour(convert24HourTo12(hour))}:${minutes} ${amPmValue(
    hour,
  )}`;
};

const prepareQuarterSlot = ({ id }, timeSlot) => {
  const [hourPart, minutesPart] = timeSlot.split(":");
  const twelveHourPart = convert24HourTo12(parseInt(hourPart, 0));
  return {
    id: `${id}-${timeSlot}`,
    label: `${twelveHourPart}:${minutesPart}`,
    value: timeSlot,
  };
};

const filterTimeSlots = (timeSlots, minHour, maxHour = null) => {
  let filteredSlots = [];

  if (maxHour === null) {
    filteredSlots = timeSlots.filter((slot) => parseInt(slot, 0) >= minHour);
  } else if (minHour === null) {
    filteredSlots = timeSlots.filter((slot) => parseInt(slot, 0) < maxHour);
  } else {
    filteredSlots = timeSlots.filter(
      (slot) => parseInt(slot, 0) >= minHour && parseInt(slot, 0) < maxHour,
    );
  }

  return filteredSlots;
};

const prepareHourSlots = ({ id, appointmentSlots }, [minHour, maxHour]) =>
  filterTimeSlots(appointmentSlots, minHour, maxHour).reduce(
    (timeSlots, currentSlot) => {
      const currentSlotKey = parseInt(currentSlot, 0);
      const matchingTimeSlot = timeSlots.find(
        (slot) => slot.value === currentSlotKey,
      ) || {
        id: `${id}-${currentSlotKey}`,
        label: hourSlotLabel(currentSlotKey),
        value: currentSlotKey,
        quarterSlots: [],
      };
      return [
        ...timeSlots.filter((slot) => slot.value !== currentSlotKey),
        {
          ...matchingTimeSlot,
          quarterSlots: [
            ...matchingTimeSlot.quarterSlots,
            prepareQuarterSlot(matchingTimeSlot, currentSlot),
          ],
        },
      ];
    },
    [],
  );

export const prepareTimeOfDaySlots = (selectedDate) =>
  [
    {
      id: `${selectedDate.id}-early-morning`,
      label: "Early morning",
      hourSlots: prepareHourSlots(selectedDate, [null, 10]),
    },
    {
      id: `${selectedDate.id}-mid-morning`,
      label: "Mid morning",
      hourSlots: prepareHourSlots(selectedDate, [10, 13]),
    },
    {
      id: `${selectedDate.id}-after-lunch`,
      label: "After lunch",
      hourSlots: prepareHourSlots(selectedDate, [13, 16]),
    },
    {
      id: `${selectedDate.id}-over-night`,
      label: "Evening / over night",
      hourSlots: prepareHourSlots(selectedDate, [16]),
    },
  ].filter((timeOfDaySlot) => timeOfDaySlot.hourSlots.length > 0);

export const twelveHourTimeFormat = (time) => {
  const [hourPart, minutesPart] = time.split(":");
  const twelveHourPart = convert24HourTo12(parseInt(hourPart, 0));
  const amPm = amPmValue(hourPart);
  return `${twelveHourPart}:${minutesPart} ${amPm}`;
};
