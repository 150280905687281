import React from "react";
import { Route } from "react-router-dom";
import * as routes from "shared/constants/routes";
import Footer from "components/common/Footer";
import Header from "components/common/Header";
import BookingEdit from "./BookingEdit";
import DealershipInfo from "./DealershipInfo";
import Main from "./Main";
import ModifyOrReschedule from "./ModifyOrReschedule";
import QrCode from "./QrCode";
import Reschedule from "./Reschedule";
import styles from "./styles.module.scss";

const CustomerView = ({ slug }) => (
  <>
    <Route path={routes.dealershipInfoPath(slug)}>
      <Header arrowBack />
      <div style={{ overflowY: "auto" }}>
        <DealershipInfo />
        <Footer />
      </div>
    </Route>
    <Route path={routes.qrCodePath(slug)}>
      <Header arrowBack />
      <div className={styles.pageWrapper}>
        <QrCode />
        <Footer />
      </div>
    </Route>
    <Route path={routes.chooseActionPath(slug)}>
      <Header
        arrowBack
        title={"Modify or Reschedule"}
      />
      <div className={styles.pageWrapper}>
        <ModifyOrReschedule />
      </div>
      <Footer />
    </Route>
    <Route path={routes.reschedulePath(slug)}>
      <Header
        arrowBack
        title={"Reschedule"}
      />
      <Reschedule />
    </Route>
    <Route path={routes.modifyPath(slug)}>
      <BookingEdit isEditing />
    </Route>
    <Route path={routes.detailsPath(slug)}>
      <BookingEdit />
    </Route>
    <Route
      exact
      path={routes.customerPath(slug)}
    >
      <Header arrowBack={false} />
      <div className={styles.pageWrapper}>
        <Main />
        <Footer />
      </div>
    </Route>
  </>
);

export default CustomerView;
