import React from "react";
import { CSSTransition } from "react-transition-group";
import { node, number, oneOf } from "prop-types";

export const SLIDE_UP = "slideUp";
export const UNFOLD_RIGHT = "unfoldRight";
export const FADE_IN = "fadeIn";

const TIMEOUT = 1500;

const Animation = ({ delay, type, children, ...props }) => (
  <CSSTransition
    classNames={type}
    timeout={TIMEOUT + delay}
    {...props}
  >
    <div className={`animation transition-delay-${delay}`}>{children}</div>
  </CSSTransition>
);

Animation.propTypes = {
  delay: number,
  type: oneOf([SLIDE_UP, UNFOLD_RIGHT, FADE_IN]),
  children: node.isRequired,
};

Animation.defaultProps = {
  type: SLIDE_UP,
  delay: 0,
};

export default Animation;
