import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { connect } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import cx from "classnames";
import { CHECKIN_CHANNEL } from "shared/constants/action-cable";
import { checkinWelcomePath } from "shared/constants/routes";
import "shared/styles/globals.scss";
import { dealershipStylesheetUrl } from "shared/utils";
import useCurrentHeight from "shared/utils/useCurrentHeight.js";
import { actionCableListener } from "store/actions/app-actions";
import { storeAppointmentGuid } from "store/actions/app-actions";
import { initializeCheckIn } from "store/actions/chat-actions";
import {
  appCableChannelKeySelector,
  appDealershipIdSelector,
  appDealershipSlugSelector,
} from "store/selectors/app-selectors";
import ActionCable from "components/common/ActionCable";
import CheckInView from "components/views/CheckInView";
import CustomerView from "components/views/CustomerView";

const App = ({
  channelKey,
  actionCableReceiver,
  initializeCheckIn,
  storeAppointmentGuid,
  dealershipSlug,
  dealershipId,
}) => {
  const [slug, setSlug] = useState(null);
  const [cookies, setCookie] = useCookies(["dealershipSlug"]);
  const height = useCurrentHeight();

  useEffect(() => {
    if (slug) {
      initializeCheckIn(slug);
      setCookie("dealershipSlug", slug);
    }
  }, [slug, initializeCheckIn, setCookie]);

  return (
    <div
      className={cx("appRoot", "dealership-color-scheme-container", {
        "make-mazda":
          // dealershipSlug === "dt-dealership" ||
          // dealershipSlug === "demo-dealership" ||
          dealershipSlug === "mcdonald-mazda-south",
      })}
      style={{ height }}
    >
      {dealershipId && (
        <link
          rel="stylesheet"
          type="text/css"
          href={dealershipStylesheetUrl(dealershipId)}
        />
      )}
      <BrowserRouter>
        <>
          <Route
            exact
            path={"/"}
            render={() => {
              if (cookies.dealershipSlug) {
                return <Redirect to={`/${cookies.dealershipSlug}`} />;
              } else {
                return <h1>Open recent sms and use link from there</h1>;
              }
            }}
          />
          <Route
            exact
            path={["/:slug/*", "/:slug"]}
            render={({
              match: {
                params: { slug },
              },
              location: { search },
            }) => {
              setSlug(slug);

              const params = new URLSearchParams(search);
              const guid = params.get("guid");

              if (guid) {
                storeAppointmentGuid(guid);
              }

              return (
                <Switch>
                  <>
                    <CheckInView slug={slug} />
                    <CustomerView slug={slug} />
                    <Route
                      path={`/${slug}`}
                      render={() => <Redirect to={checkinWelcomePath(slug)} />}
                    />
                  </>
                </Switch>
              );
            }}
          />
        </>
      </BrowserRouter>
      <ActionCable
        channel={CHECKIN_CHANNEL}
        channelKey={channelKey}
        onReceive={actionCableReceiver}
      />
    </div>
  );
};

App.defaultProps = {
  channelKey: null,
  dealershipId: null,
};

const mapStateToProps = (state) => ({
  channelKey: appCableChannelKeySelector(state),
  dealershipSlug: appDealershipSlugSelector(state),
  dealershipId: appDealershipIdSelector(state),
});

const actions = {
  storeAppointmentGuid,
  initializeCheckIn,
  actionCableReceiver: actionCableListener,
};

export default connect(mapStateToProps, actions)(App);
