import React from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { number, string } from "prop-types";
import { authPath, customerPath } from "shared/constants/routes";
import welcomeScreenTypes from "shared/constants/welcome-screen-types";
import { changeWelcomeScreenType } from "store/actions/welcome-actions";
import {
  appDealershipIdSelector,
  appDealershipLogoSelector,
  appDealershipNameSelector,
  appDealershipSlugSelector,
  appIsNoAppointmentByGuidSelector,
  appWelcomeScreenTypeSelector,
} from "store/selectors/app-selectors";
import { chatIsCustomerPagesInitializedSelector } from "store/selectors/chat-selectors";
import Button from "components/common/Button";
import Footer from "components/common/Footer";
import Header from "components/common/Header";
import spinnerIcon from "assets/images/spinner.svg";
import styles from "./styles.module.scss";

const WelcomePage = ({
  dealershipId,
  dealershipSlug,
  dealershipLogo,
  dealershipName,
  welcomeScreenType,
  isCustomerPagesInitialized,
  isNoAppointmentByGuid,
  changeWelcomeScreenType,
}) => {
  const history = useHistory();
  const { button, caption } = welcomeScreenTypes[welcomeScreenType];

  const handleClick = () => {
    switch (welcomeScreenType) {
      case "checkin": {
        history.push(authPath(dealershipSlug));
        break;
      }
      case "error":
      case "cancelled": {
        window.open(
          `https://reserve.getcarmen.com/?dealershipId=${dealershipId}`,
        );
        break;
      }

      default:
        break;
    }
  };

  if (isCustomerPagesInitialized)
    return <Redirect to={customerPath(dealershipSlug)} />;
  if (isNoAppointmentByGuid) return <Redirect to={authPath(dealershipSlug)} />;

  return (
    <>
      {welcomeScreenType === "error" ? (
        <Header
          arrowBack
          arrowBackColor={"#0BCAF9"}
          onClick={() => {
            changeWelcomeScreenType("checkin");
            history.push(authPath(dealershipSlug));
          }}
        />
      ) : null}
      <div className={styles.container}>
        <div className={styles.background}>
          <div className={styles.circle}>
            <div className={styles.circle}>
              <div className={styles.circle}>
                <div className={styles.circle}></div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {!!dealershipLogo && (
            <img
              className={styles.dealershipLogo}
              src={dealershipLogo}
              alt={dealershipName}
            />
          )}
          <h1 className={styles.title}>
            Welcome
            {dealershipName && (
              <>
                {" "}
                to
                <br />
                {dealershipName}
              </>
            )}
            !
          </h1>
          {welcomeScreenType !== "edit" ? (
            <>
              <Button
                caption={button}
                isWide
                onClick={() => handleClick()}
              />
              <div className={styles.text}>{caption}</div>
            </>
          ) : (
            <div>
              <img
                alt="loading"
                className={styles.loadingState}
                src={spinnerIcon}
              />
            </div>
          )}
        </div>
      </div>
      <Footer isDark />
    </>
  );
};

WelcomePage.propTypes = {
  dealershipId: number,
  dealershipName: string,
  welcomeScreenType: string,
};

const mapStateToProps = (state) => ({
  dealershipSlug: appDealershipSlugSelector(state),
  isCustomerPagesInitialized: chatIsCustomerPagesInitializedSelector(state),
  isNoAppointmentByGuid: appIsNoAppointmentByGuidSelector(state),
  dealershipId: appDealershipIdSelector(state),
  dealershipName: appDealershipNameSelector(state),
  dealershipLogo: appDealershipLogoSelector(state),
  welcomeScreenType: appWelcomeScreenTypeSelector(state),
});

const WelcomePageContainer = connect(mapStateToProps, {
  changeWelcomeScreenType,
})(WelcomePage);

export default WelcomePageContainer;
