export default {
  checkin: {
    button: "Start Check In",
    caption:
      "Save your time utilizing our exclusive online checkin process and check in online now",
  },
  edit: {
    button: "Start Editing",
    caption: "Edit or Reschedule your appointment.",
  },
  error: {
    button: "Book appointment",
    caption: "You have no appointment created.",
  },
  cancelled: {
    button: "Book appointment",
    caption: "Your appointment has been cancelled.",
  },
};

export const GUID_INITIAL = "Edit or Reschedule your appointment";
export const PHONE_INITIAL =
  "Save your time utilizing our exclusive online checkin process and check in online now";
export const APPOINTMENT_CANCELLED = "Your appointment has been cancelled.";
export const APPOINTMENT_NOT_FOUND = "You have no appointment created.";
export const BUTTON_BOOK = "Book appointment";
export const BUTTON_START_EDIT = "Start Editing";
export const BUTTON_START_CHECKIN = "Start Check In";
