import { Component } from "react";
import { func, string } from "prop-types";
import Cable from "actioncable";
import { getCableUrl } from "shared/utils";

class ActionCable extends Component {
  state = {
    cable: null,
    subscription: null,
  };

  componentDidMount() {
    const cable = Cable.createConsumer(getCableUrl());
    this.setState({ cable });
  }

  componentDidUpdate(prevProps) {
    this.handleSubscriptions(prevProps);
  }

  componentWillUnmount() {
    const { cable, subscription } = this.state;
    if (subscription) {
      cable.subscriptions.remove(subscription);
    }
  }

  handleReceive = (data) => this.props.onReceive(data);

  handleSubscriptions(prevProps) {
    const { cable, subscription } = this.state;

    if (this.props.channelKey !== prevProps.channelKey) {
      if (subscription) {
        cable.subscriptions.remove(subscription);
      }
      console.log(this.props);
      const newSubscription = cable.subscriptions.create(
        {
          channel: this.props.channel,
          channel_key: this.props.channelKey,
        },
        {
          received: this.handleReceive,
        },
      );
      this.setState({
        subscription: newSubscription,
      });
    }
  }

  render() {
    return null;
  }
}

ActionCable.propTypes = {
  channel: string,
  channelKey: string,
  onReceive: func.isRequired,
};

ActionCable.defaultProps = {
  channel: null,
  channelKey: null,
};

export default ActionCable;
