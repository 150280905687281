import React, { Fragment } from "react";
import { connect } from "react-redux";
import { string } from "prop-types";
import { appDealershipNameSelector } from "store/selectors/app-selectors";
import { chatCustomerNameSelector } from "store/selectors/chat-selectors";
import AnimatedTextMessage from "components/common/AnimatedTextMessage";
import AnimationGroup from "components/common/AnimationGroup";
import { SOURCE_CONCIERGE } from "components/common/TextMessage";

const GreetingStep = ({ customerName, dealershipName }) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        message={`Hello, ${customerName}. Welcome to ${dealershipName}. My name is Carmen!`}
        source={SOURCE_CONCIERGE}
        isOpening
      />
    </AnimationGroup>
  </Fragment>
);

GreetingStep.propTypes = {
  customerName: string.isRequired,
  dealershipName: string.isRequired,
};

const mapStateToProps = (state) => ({
  dealershipName: appDealershipNameSelector(state),
  customerName: chatCustomerNameSelector(state),
});

export default connect(mapStateToProps, null)(GreetingStep);
