import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import {
  appDealershipLogoSelector,
  appDealershipNameSelector,
} from "store/selectors/app-selectors";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrow/left.svg";
import styles from "./styles.module.scss";

const Header = (props) => {
  const { arrowBack, title, onClick, dealershipLogo, dealershipName, isFixed } =
    props;

  const history = useHistory();

  return arrowBack ? (
    <div
      className={cx(styles.header, {
        [styles.fixed]: isFixed,
      })}
    >
      <div className={styles.headerWrap}>
        <div
          className={styles.goBack}
          onClick={onClick ? () => onClick() : () => history.goBack()}
        >
          <IconArrowLeft />
          <span>Back</span>
        </div>
        {title ? (
          <>
            <p className={styles.title}>{title}</p>
            <span className={styles.hidden}></span>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <div
      className={cx(styles.header, {
        [styles.fixed]: isFixed,
      })}
    >
      <span className={styles.dealerName}>{dealershipName}</span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dealershipName: appDealershipNameSelector(state),
  dealershipLogo: appDealershipLogoSelector(state),
});
export default connect(mapStateToProps)(Header);
