import React from "react";
import styles from "./styles.module.scss";

const Button = ({
  color,
  bgColor,
  boxShadow,
  border,
  disabledColor,
  disabledBgColor,
  text,
  onClick,
  disabled,
}) => {
  return (
    <button
      disabled={disabled}
      style={
        !disabled
          ? {
              color: color,
              background: bgColor,
              boxShadow: boxShadow,
              border: border,
            }
          : {
              color: disabledColor,
              background: disabledBgColor,
            }
      }
      onClick={onClick}
      className={styles.btn}
    >
      {text}
    </button>
  );
};

export default Button;
