export const appCableChannelKeySelector = (state) =>
  state.app.actionCableChannelKey;
export const appCheckinIdSelector = (state) => state.app.checkinId;
export const appAppointmentGuidSelector = (state) => state.app.appointmentGuid;
export const appAppointmentSlotsSelector = (state) =>
  state.app.appointmentSlots;
export const appJobSlotsSelector = (state) => state.app.jobSlots;
export const appMobileTechnicianSlotsSelector = (state) => state.app.mobileTechnicianSlots;
export const appIsNoAppointmentByGuidSelector = (state) =>
  state.app.isNoAppointmentByGuid;
export const appIsNoAppointmentByPhoneNumberSelector = (state) =>
  state.app.isNoAppointmentByPhoneNumber;
export const appIsNoCustomerByPhoneNumberSelector = (state) =>
  state.app.isNoCustomerByPhoneNumber;

export const appDealershipSlugSelector = (state) => state.app.dealershipSlug;
export const appDealershipIdSelector = (state) => state.app.dealership.id;
export const appDealershipPublicIdSelector = (state) =>
  state.app.dealership.publicId;
export const appDealershipAddressSelector = (state) =>
  state.app.dealership.address;
export const appDealershipHoursSelector = (state) =>
  state.app.dealership.workingHours;
export const appDealershipPhoneSelector = (state) => state.app.dealership.phone;
export const appDealershipEmailSelector = (state) => state.app.dealership.email;
export const appDealershipNotesSelector = (state) => state.app.dealership.notes;
export const appDealershipNameSelector = (state) => state.app.dealership.name;
export const appDealershipLogoSelector = (state) => state.app.dealership.logo;
export const appDealershipLocationSelector = (state) => ({
  lat: state.app.dealership.latitude,
  lng: state.app.dealership.longitude,
});
export const appDealershipTaxRateSelector = (state) =>
  state.app.dealership.taxRate;
export const appDealershipWelcomeScreenSelector = (state) =>
  state.app.dealership.welcomeScreen;
export const appDealershipDmsTypeSelector = (state) =>
  state.app.dealership.dmsType;
export const appDealershipTimeZoneSelector = (state) =>
  state.app.dealership.timeZone;

export const appWelcomeScreenTypeSelector = (state) =>
  state.app.welcomeScreenType;
export const appServicesPriceVisibleSelector = (state) =>
  state.app.dealership.servicesPriceVisible;
export const appDealershipRemoteEnabledSelector = (state) =>
  state.app.dealership.remoteEnabled;
export const appDealershipMobileTechnicianEnabledSelector = (state) =>
  state.app.dealership.mobileTechnicianEnabled;