import {
  fetchAppointmentByGuid,
  fetchAppointmentByPhoneNumber,
} from "shared/api";
import { appCheckinIdSelector } from "store/selectors/app-selectors";
import { initializeChat, setLoadingState } from "./chat-actions";

export const FETCH_APPOINTMENT_DETAILS_SUCCESS =
  "FETCH_APPOINTMENT_DETAILS_SUCCESS";
export const FETCH_APPOINTMENT_DETAILS_ERROR =
  "FETCH_APPOINTMENT_DETAILS_ERROR";
export const FETCHING_APPOINTMENT_BY_GUID = "FETCHING_APPOINTMENT_BY_GUID";
export const FETCHING_APPOINTMENT_BY_PHONE_NUMBER =
  "FETCHING_APPOINTMENT_BY_PHONE_NUMBER";
export const CHANGE_WELCOME_SCREEN_TYPE = "CHANGE_WELCOME_SCREEN_TYPE";

export const changeWelcomeScreenType = (type) => ({
  type: CHANGE_WELCOME_SCREEN_TYPE,
  payload: type,
});

export const importAppointmentByGuid = (guid) => (dispatch, getState) => {
  dispatch(setLoadingState());
  dispatch({
    type: FETCHING_APPOINTMENT_BY_GUID,
    payload: { isFetchedByGuid: true },
  });
  fetchAppointmentByGuid(appCheckinIdSelector(getState()), guid);
};

export const importAppointmentByPhoneNumber =
  (phoneNumber) => (dispatch, getState) => {
    dispatch(setLoadingState());
    dispatch({
      type: FETCHING_APPOINTMENT_BY_PHONE_NUMBER,
      payload: { isFetchedByPhoneNumber: true },
    });
    fetchAppointmentByPhoneNumber(
      appCheckinIdSelector(getState()),
      phoneNumber,
    );
  };

export const initializeNewBooking = () => (dispatch) => {
  dispatch(setLoadingState(false));
  dispatch(initializeChat());
};
