import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bool, string } from "prop-types";
import { DELAY_500, DELAY_1000, DELAY_1500 } from "shared/constants/delays";
import { chatIsPickupJobCheckinSelector } from "store/selectors/chat-selectors";
import AnimatedTextMessage from "components/common/AnimatedTextMessage";
import Animation from "components/common/Animation";
import AnimationGroup from "components/common/AnimationGroup";
import BookingDetails from "components/common/BookingDetails";
import { SOURCE_CONCIERGE, SOURCE_USER } from "components/common/TextMessage";

const BookingConfirmationStep = ({
  isPickupJobCheckin,
  delayed,
  isEditing,
  isComplete,
  textFromUser,
  isBare,
}) => {
  return (
    <Fragment>
      <AnimationGroup>
        {!isBare && (
          <AnimatedTextMessage
            delay={delayed ? DELAY_1000 : DELAY_500}
            message={`Please confirm your ${
              isPickupJobCheckin ? "pickup" : "reservation"
            }:`}
            source={SOURCE_CONCIERGE}
          />
        )}
        <Animation delay={delayed ? DELAY_1500 : DELAY_1000}>
          <BookingDetails isEditing={isEditing} />
        </Animation>
      </AnimationGroup>
      {isComplete && (
        <AnimationGroup>
          <AnimatedTextMessage
            message="Yeah, that's right"
            source={SOURCE_USER}
          />
        </AnimationGroup>
      )}
      {textFromUser && (
        <AnimationGroup>
          <AnimatedTextMessage
            message={textFromUser}
            source={SOURCE_USER}
          />
        </AnimationGroup>
      )}
    </Fragment>
  );
};

BookingConfirmationStep.propTypes = {
  textFromUser: string,
  isComplete: bool,
  isEditing: bool,
  delayed: bool,
};

BookingConfirmationStep.defaultProps = {
  textFromUser: "",
  isComplete: false,
  delayed: false,
  isEditing: false,
};

const mapStateToProps = (state) => ({
  isPickupJobCheckin: chatIsPickupJobCheckinSelector(state),
});

const BookingConfirmationStepContainer = connect(mapStateToProps)(
  BookingConfirmationStep,
);

export default BookingConfirmationStepContainer;
