import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bool, func, object, string } from "prop-types";
import { DELAY_1500 } from "shared/constants/delays";
import { customerPath } from "shared/constants/routes";
import { exit } from "store/actions/app-actions";
import { appDealershipSlugSelector } from "store/selectors/app-selectors";
import Button from "components/common/Button";
import animationWrapper from "components/common/animationWrapper";
import styles from "./styles.module.scss";

class FinalStepInput extends Component {
  componentDidMount() {
    setTimeout(this.exit, 15000);
  }

  exit = () => {
    this.props.onExit();
    this.props.history.push(customerPath(this.props.dealershipSlug));
  };

  render() {
    return (
      this.props.isComplete && (
        <div className={styles.chatInput}>
          <Button
            delay={DELAY_1500}
            caption="Thanks"
            onClick={this.exit}
          />
        </div>
      )
    );
  }
}

FinalStepInput.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  history: object.isRequired,
  classification: object,
  /* eslint-enable react/forbid-prop-types */
  onExit: func.isRequired,
  isComplete: bool,
  modelName: string,
  transcription: string,
};

FinalStepInput.defaultProps = {
  isComplete: false,
  classification: null,
  transcription: "",
  modelName: "",
};

const mapStateToProps = (state) => ({
  dealershipSlug: appDealershipSlugSelector(state),
});

const actions = {
  onExit: exit,
};

const FinalStepInputContainer = connect(
  mapStateToProps,
  actions,
)(FinalStepInput);

export default animationWrapper(
  withRouter(FinalStepInputContainer),
  DELAY_1500,
);
