import {
  arrayOf,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { SOURCE_CONCIERGE, SOURCE_USER } from "components/common/TextMessage";

const TYPE_QUESTION = "question";
const TYPE_TEXT = "text";

export const servicePropType = shape({
  id: number.isRequired,
  name: string.isRequired,
  allocated_labor_time: number,
  fee: number.isRequired,
  additional_note: string.isRequired,
});

export const servicesPropType = arrayOf(servicePropType);

export const availableServicesPropType = shape({
  maintenance: arrayOf(
    shape({
      id: number,
      name: string,
    }),
  ),
  concern: arrayOf(
    shape({
      id: number,
      name: string,
    }),
  ),
});

export const bookingPropType = shape({
  customer: string.isRequired,
  vehicle: string.isRequired,
  services: shape({
    maintenance: servicesPropType,
    concern: servicesPropType,
    extensions: servicesPropType,
    recalls: servicesPropType,
  }).isRequired,
  totalPrice: number.isRequired,
  totalTime: string.isRequired,
});

export const chatHistoryPropType = arrayOf(
  shape({
    id: number.isRequired,
    component: oneOfType([func, object]).isRequired,
    props: object.isRequired,
  }),
);

export const decisionTreeResultPropType = shape({
  serviceId: number,
  conversation: arrayOf(
    shape({
      text: string,
      source: oneOf([SOURCE_CONCIERGE, SOURCE_USER]),
    }),
  ),
});

export const decisionTreePropType = shape({
  serviceId: number,
  intro: shape({
    text: string,
    goto: string,
  }),
  steps: arrayOf(
    shape({
      type: oneOf([TYPE_QUESTION, TYPE_TEXT]),
      text: string,
      answers: arrayOf(
        shape({
          text: string,
          goto: string,
        }),
      ),
    }),
  ),
});

export const vehiclePropType = shape({
  id: number,
  name: string,
  make: string,
  model: string,
  year: number,
  state: string,
});

export const appointmentListItemPropType = shape({
  id: number,
  appointmentNumber: string,
  carPlateNumber: string,
  carRegion: string,
  carMake: string,
  carModel: string,
  carYear: number,
  appointmentDateTime: string,
});

export const uberPropType = shape({
  driverName: string,
  driverPicture: string,
  driverPhone: string,
  time: number,
  vehicle: string,
});

const dayWorkingHoursPropType = shape({
  from: string,
  to: string,
  order: number,
});

export const dealershipWorkingHoursPropType = shape({
  mon: dayWorkingHoursPropType,
  tue: dayWorkingHoursPropType,
  wed: dayWorkingHoursPropType,
  thu: dayWorkingHoursPropType,
  fri: dayWorkingHoursPropType,
  sat: dayWorkingHoursPropType,
  sun: dayWorkingHoursPropType,
});

export const appointmentSlotPropType = shape({
  full_date: string,
  short_day_name: string,
  day_name: string,
  day_of_month: string,
  slots: arrayOf(string),
});

export const timeSlotPropType = shape({
  label: string,
  value: number,
});

export const quarterSlotPropType = shape({
  id: string,
  label: string,
  value: string,
});

export const hourSlotPropType = shape({
  id: string,
  label: string,
  value: number,
  quarterSlots: arrayOf(quarterSlotPropType),
});

export const timeOfDaySlotPropType = shape({
  id: string,
  label: string,
  hourSlots: arrayOf(hourSlotPropType),
});
