import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { bool, oneOf, string } from "prop-types";
import styles from "./styles.module.scss";

export const SOURCE_CONCIERGE = "SOURCE_CONCIERGE";
export const SOURCE_USER = "SOURCE_USER";

const renderMessage = (message, { isOpening, isClosing, isBig } = {}) => (
  <div
    className={cx(isBig ? styles.bigMessage : styles.message, {
      [styles.opening]: isOpening,
      [styles.closing]: isClosing,
    })}
  >
    {message}
  </div>
);

class TextMessage extends Component {
  render() {
    const { source, message, isOpening, isClosing, isBig } = this.props;

    return source === SOURCE_CONCIERGE ? (
      <div className={styles.container}>
        {renderMessage(message, {
          isOpening,
          isClosing,
          isBig,
        })}
      </div>
    ) : (
      <div className={cx(styles.container, styles.user)}>
        {renderMessage(message, { isBig })}
      </div>
    );
  }
}

TextMessage.propTypes = {
  source: oneOf([SOURCE_CONCIERGE, SOURCE_USER]).isRequired,
  message: string.isRequired,
  isOpening: bool,
  isClosing: bool,
  isBig: bool,
};

TextMessage.defaultProps = {
  isOpening: false,
  isClosing: false,
  isBig: false,
};

const TextMessageContainer = connect(null, null)(TextMessage);

export default TextMessageContainer;
