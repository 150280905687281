import React, { Fragment } from "react";
import { bool, string } from "prop-types";
import AnimatedTextMessage from "components/common/AnimatedTextMessage";
import AnimationGroup from "components/common/AnimationGroup";
import { SOURCE_CONCIERGE } from "components/common/TextMessage";

const ErrorStep = ({ error, canRetry }) => (
  <AnimationGroup>
    {canRetry ? (
      <Fragment>
        <AnimatedTextMessage
          message={
            error || "Sorry, our application servers responded with an error."
          }
          source={SOURCE_CONCIERGE}
          isOpening
        />
        <AnimatedTextMessage
          message="You can come back later or retry the failed request now."
          source={SOURCE_CONCIERGE}
          isClosing
        />
      </Fragment>
    ) : (
      <AnimatedTextMessage
        message={
          error || "An application error has occured. Please try again later."
        }
        source={SOURCE_CONCIERGE}
        isClosing
      />
    )}
  </AnimationGroup>
);

ErrorStep.propTypes = {
  error: string,
  canRetry: bool,
};

ErrorStep.defaultProps = {
  error: "",
  canRetry: false,
};

export default ErrorStep;
