export const authPath = (slug) => `/${slug}/auth`;
export const customerPath = (slug) => `/${slug}/customer`;
export const dealershipInfoPath = (slug) => `/${slug}/customer/dealership`;
export const qrCodePath = (slug) => `/${slug}/customer/qr_code`;
export const chooseActionPath = (slug) => `/${slug}/customer/choose_action`;
export const reschedulePath = (slug) => `/${slug}/customer/reschedule`;
export const modifyPath = (slug) => `/${slug}/customer/modify`;
export const detailsPath = (slug) => `/${slug}/customer/details`;
// export const checkinWelcomePath = () => "/checkin/welcome`;
export const checkinWelcomePath = (slug) => `/${slug}/checkin/welcome`;
export const checkinChatPath = (slug) => `/${slug}/checkin/chat`;
