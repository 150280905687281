import React from "react";
import cx from "classnames";
import { timeInUSFormat } from "shared/timeSlotUtils";
import styles from "./styles.module.scss";

const TimePicker = ({
  slots,
  timeArr,
  time,
  setTime,
  isDisabled,
  isHoursOnly,
  height,
}) => (
  <div
    className={cx(styles.picker, {
      [styles.isEmpty]: slots.length === 0,
    })}
    style={{ height: `${height}px` }}
  >
    <div className={styles.pickerView}>
      {slots.length === 0 && (
        <div className={styles.textContainer}>
          <p>Please select a date</p>
        </div>
      )}
      {slots.map((currTime, i) => {
        if (isHoursOnly && +currTime.slice(-2) !== 0) {
          return null;
        }
        return (
          <button
            key={i}
            className={cx(styles.pickerViewBtn, styles.btn, {
              [styles.active]: currTime === time,
            })}
            onClick={() => {
              setTime(currTime);
            }}
            disabled={isDisabled || !timeArr.find((time) => time === currTime)}
          >
            {timeInUSFormat(currTime)}
          </button>
        );
      })}
    </div>
  </div>
);

export default TimePicker;
