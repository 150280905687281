import React, { Fragment } from "react";
import { string } from "prop-types";
import { DELAY_500 } from "shared/constants/delays";
import AnimatedTextMessage from "components/common/AnimatedTextMessage";
import AnimationGroup from "components/common/AnimationGroup";
import { SOURCE_CONCIERGE, SOURCE_USER } from "components/common/TextMessage";

const DecisionTreeStep = ({ question, answer }) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message={question}
        source={SOURCE_CONCIERGE}
      />
    </AnimationGroup>
    {answer && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={answer}
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

DecisionTreeStep.propTypes = {
  question: string.isRequired,
  answer: string,
};

DecisionTreeStep.defaultProps = {
  answer: "",
};

export default DecisionTreeStep;
