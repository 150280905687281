import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import { DELAY_500, DELAY_1000 } from "shared/constants/delays";
import { chatIsPickupJobCheckinSelector } from "store/selectors/chat-selectors";
import AnimatedTextMessage from "components/common/AnimatedTextMessage";
import Animation from "components/common/Animation";
import AnimationGroup from "components/common/AnimationGroup";
import BookingDetails from "components/common/BookingDetails";
import { SOURCE_CONCIERGE, SOURCE_USER } from "components/common/TextMessage";

const BookingSummaryStep = ({ isPickupJobCheckin, isComplete, isEditing }) => {
  return (
    <Fragment>
      <AnimationGroup>
        <AnimatedTextMessage
          delay={DELAY_500}
          message={`Great! Here is the summary of your ${
            isPickupJobCheckin ? "pickup" : "reservation"
          }`}
          source={SOURCE_CONCIERGE}
          isOpening
        />
        <Animation delay={DELAY_1000}>
          <BookingDetails isEditing={isEditing} />
        </Animation>
      </AnimationGroup>
      {isComplete && (
        <AnimationGroup>
          <AnimatedTextMessage
            message="Great, let's do this!"
            source={SOURCE_USER}
          />
        </AnimationGroup>
      )}
    </Fragment>
  );
};

BookingSummaryStep.propTypes = {
  isPickupJobCheckin: bool.isRequired,
  isComplete: bool,
  isEditing: bool,
};

BookingSummaryStep.defaultProps = {
  isComplete: false,
  isEditing: false,
};

const mapStateToProps = (state) => ({
  isPickupJobCheckin: chatIsPickupJobCheckinSelector(state),
});

const BookingSummaryStepContainer =
  connect(mapStateToProps)(BookingSummaryStep);

export default BookingSummaryStepContainer;
