import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { confirmBookingServices } from "store/actions/chat-actions";
import {
  chatBookingStepInputSelector,
  chatBookingStepSelector,
} from "store/selectors/chat-selectors.js";
import Header from "components/common/Header";
import BookingConfirmationStep from "components/steps/visit/BookingConfirmationStep";
import BookingConfirmationStepInput from "components/steps/visit/BookingConfirmationStep/Input";
import styles from "./styles.module.scss";

const BookingEdit = ({
  bookingStepProps,
  bookingStepInputProps,
  isEditing,
  confirmBookingServices,
}) => {
  const history = useHistory();
  useEffect(() => {
    console.log(bookingStepProps);
  }, [bookingStepProps]);
  return (
    <>
      <Header
        arrowBack={true}
        title={"Reservation details"}
        arrowBackColor={"#0BCAF9"}
      />

      <div className={styles.container}>
        <BookingConfirmationStep
          isBare
          {...bookingStepProps}
        />
      </div>
      <BookingConfirmationStepInput
        {...bookingStepInputProps}
        onEditSave={() => {
          confirmBookingServices();
          history.goBack();
        }}
        isEditing={isEditing}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  bookingStepProps: chatBookingStepSelector(state),
  bookingStepInputProps: chatBookingStepInputSelector(state),
});

export default connect(mapStateToProps, {
  confirmBookingServices,
})(BookingEdit);
