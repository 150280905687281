import React, { Component, Fragment } from "react";
import InlineSVG from "react-inlinesvg";
import { connect } from "react-redux";
import cx from "classnames";
import { arrayOf, func, number, shape, string } from "prop-types";
import {
  availableServicesPropType,
  servicesPropType,
} from "shared/constants/prop-types";
import { makeFloat } from "shared/utils";
import { appServicesPriceVisibleSelector } from "store/selectors/app-selectors";
import { chatAvailableServicesSelector } from "store/selectors/chat-selectors";
import Button from "components/common/Button";
import Header from "components/common/Header";
import PackageItems from "components/common/PackageItems";
import selectedIcon from "assets/icons/services/service-checked.svg";
import unselectedIcon from "assets/icons/services/service-plus.svg";
import styles from "./styles.module.scss";

const MAINTENANCE_TAB = "MAINTENANCE";

const MAINTENANCE_HEADER = "Maintenance";
const CONCERN_HEADER = "Concern";

class ServiceSelector extends Component {
  closeModal = () => {
    this.setState({ currentTab: null });
    this.props.closeModal();
  };

  isDisabled = (service) =>
    this.props.disabledPackageItems.map((item) => item.id).includes(service.id);

  renderIcon = (className, src) => (
    <div className={className}>
      <InlineSVG src={src} />
    </div>
  );

  handleChange = (event, service) => {
    const { onNotesChange } = this.props;
    onNotesChange(event.target.value, service);
    this.setState({
      additional_note: event.target.value,
    });
  };

  renderService = (service, onClick, isSelected, isDisabled) => {
    isDisabled && console.log(service, "disabled");
    const { package_items, name, extras, fee, saving } = service;

    return (
      <button
        key={service.id}
        className={cx(styles.service, {
          [styles.highlighted]: isSelected,
          [styles.disabled]: isDisabled,
        })}
        onClick={onClick(service)}
        disabled={isDisabled}
      >
        <div className={styles.serviceBody}>
          <span className={styles.serviceName}>{name}</span>
          {isSelected
            ? this.renderIcon(styles.selectedIcon, selectedIcon)
            : this.renderIcon(styles.unselectedIcon, unselectedIcon)}
          <div className={styles.servicePriceWrap}>
            {this.props.servicesPriceVisible && (
              <div className={styles.servicePriceTag}>
                ${makeFloat(fee || 0)}
              </div>
            )}
            {package_items.length > 0 && saving > 0 && (
              <div className={styles.serviceSaveTag}>
                Save ${makeFloat(saving)}
              </div>
            )}
          </div>
        </div>
        {isSelected && !service.decision_tree && service.kind === "concern" && (
          <div className={styles.InputWrap}>
            <input
              type="text"
              placeholder="Please describe your concern"
              value={service.additional_note}
              onClick={(event) => event.stopPropagation(event)}
              onKeyUp={(event) => event.preventDefault()}
              onChange={(event) => this.handleChange(event, service)}
              maxLength={200}
            />
            <p className={styles.InputAdditionalInfo}>
              {`${
                (
                  this.props.selectedConcern.filter(
                    (concern) => concern.id === service.id,
                  )[0].additional_note || ""
                ).length
              }/200`}
            </p>
          </div>
        )}
        {package_items.length > 0 && (
          <PackageItems
            items={package_items}
            extras={extras}
            isSelected={isSelected}
          />
        )}
      </button>
    );
  };

  renderSelector = (header, services, selectedServices, toggleService) => (
    <Fragment>
      <Header
        arrowBack={true}
        title={header}
        arrowBackColor={"#0BCAF9"}
        onClick={this.closeModal}
      />
      <div className={styles.servicesContainer}>
        {services.map((service) =>
          this.renderService(
            service,
            toggleService,
            !!selectedServices.find(({ id }) => id === service.id),
            this.isDisabled(service),
          ),
        )}
      </div>
      <div className={styles.chatInput}>
        <Button
          caption="Save"
          onClick={() => this.props.addServices()}
          isWide
          disabled={selectedServices.length === 0}
        />
      </div>
    </Fragment>
  );

  renderServices = () => {
    const {
      availableServices,
      selectedMaintenance,
      selectedConcern,
      toggleMaintenance,
      toggleConcern,
      serviceKind,
    } = this.props;
    const { maintenance, concern } = availableServices;

    return serviceKind === MAINTENANCE_TAB
      ? this.renderSelector(
          MAINTENANCE_HEADER,
          maintenance,
          selectedMaintenance,
          toggleMaintenance,
        )
      : this.renderSelector(
          CONCERN_HEADER,
          concern,
          selectedConcern,
          toggleConcern,
        );
  };

  render() {
    return this.renderServices();
  }
}

ServiceSelector.propTypes = {
  availableServices: availableServicesPropType.isRequired,
  selectedMaintenance: servicesPropType.isRequired,
  selectedConcern: servicesPropType.isRequired,
  toggleMaintenance: func.isRequired,
  toggleConcern: func.isRequired,
  addServices: func.isRequired,
  closeModal: func.isRequired,
  serviceKind: string,
  disabledPackageItems: arrayOf(shape({ id: number, name: string })),
};

ServiceSelector.defaultProps = {
  disabledPackageItems: [],
};

const mapStateToProps = (state) => ({
  availableServices: chatAvailableServicesSelector(state),
  servicesPriceVisible: appServicesPriceVisibleSelector(state),
});

const ServiceSelectorContainer = connect(mapStateToProps)(ServiceSelector);

export default ServiceSelectorContainer;
