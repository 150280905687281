import React from "react";
import { connect } from "react-redux";
import { Marker, StaticGoogleMap } from "react-static-google-map";
import {
  appDealershipAddressSelector,
  appDealershipHoursSelector,
  appDealershipLocationSelector,
  appDealershipNameSelector,
  appDealershipNotesSelector,
} from "store/selectors/app-selectors";
import Button from "components/common/Button/Button";
import styles from "./styles.module.scss";

const DealershipInfo = ({ name, location, address, workingHours, notes }) => {
  const openGoogleMap = ({ lat, lng }) => {
    window.open(`https://maps.google.com/?q=${lat},${lng}`, "_blank");
  };

  return (
    <div className={styles.body}>
      <div className={styles.card}>
        <div
          className={styles.preview}
          onClick={() => openGoogleMap(location)}
        >
          <div className={styles.inner}>
            <StaticGoogleMap
              size="1280x336"
              apiKey="AIzaSyBJDDBskjWEvuEucmPNHG4vCmHrI35eU6E"
              maptype={"roadmap"}
              className={styles.imgFluid}
              scale="2"
            >
              <Marker
                location={location}
                color="0x6B46FF"
                label="o"
                size="mid"
                // TODO: Can be added like icon but only via url
              />
            </StaticGoogleMap>
          </div>
        </div>
        <div className={styles.cardBlock}>
          <div className={styles.row}>
            <div>
              <p className={styles.type}>Dealership</p>
              <p className={styles.value}>{name}</p>
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <p className={styles.type}>Address</p>
              <p className={styles.value}>{address}</p>
            </div>
          </div>
          <div className={styles.row}>
            {workingHours && (
              <div>
                <p className={styles.type}>Working hours</p>
                <div className={styles.workingHours}>
                  {Object.keys(workingHours).map((day) => (
                    <p
                      className={styles.item}
                      key={day}
                    >
                      <span className={styles.day}>
                        {day.charAt(0).toUpperCase() + day.slice(1, 2)}:
                      </span>
                      <span className={styles.time}>
                        {workingHours[day].from === "00:00" &&
                        workingHours[day].to === "00:00"
                          ? "Closed"
                          : `${workingHours[day].from}-${workingHours[day].to}`}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div style={{ marginTop: 18 }}>
            <Button
              color={"#ffffff"}
              bgColor={"#34364A"}
              text={"Open in Google Maps"}
              onClick={() => openGoogleMap(location)}
            />
          </div>
        </div>
        {notes && (
          <div className={styles.cardBlockText}>
            <p className={styles.text}>{notes}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    name: appDealershipNameSelector(state),
    location: appDealershipLocationSelector(state),
    address: appDealershipAddressSelector(state),
    workingHours: appDealershipHoursSelector(state),
    notes: appDealershipNotesSelector(state),
  };
};

export default connect(mapStateToProps)(DealershipInfo);
