import { toFixedPrecision } from "shared/utils";
import { BACK_FROM_CHAT, EXIT, NOT_SYNCED } from "store/actions/app-actions";
import * as chatActions from "store/actions/chat-actions";
import {
  INITIALIZE_DECISION_TREE,
  SET_DECISION_TREE_DESCRIPTION,
  UPDATE_DECISION_TREE,
} from "store/actions/decision-trees-actions";
import { FETCH_APPOINTMENT_DETAILS_SUCCESS } from "store/actions/welcome-actions";
import { SOURCE_CONCIERGE } from "components/common/TextMessage";
import STEPS, { MAX_PROGRESS } from "components/steps/visit";

const removeService = (collection = [], id) =>
  collection.filter(
    (service) => service.id !== id && service.menu_item_id !== id,
  );

const toggleService = (collection, service) =>
  collection.find(({ id }) => service.id === id)
    ? collection.filter(({ id }) => id !== service.id)
    : collection.concat(service);

const removeCorrespondingServices = (services, lineItems, id) => {
  const lineItem = lineItems.find((item) => item.id === id);

  return {
    maintenance: services.maintenance.filter(
      ({ name }) => name !== lineItem.name,
    ),
    concern: services.concern.filter(({ name }) => name !== lineItem.name),
  };
};

const initialState = {
  lastId: 0,
  isLoading: false,
  history: [],
  order: {
    services: {},
    jobs: [],
    lineItems: [],
    preselectedRecalls: [],
    selectedRecalls: [],
    bookingsMenuItems: [],
    additionalServices: [],
    customer: {
      first_name: "Test",
      last_name: "Aga",
      email: "aga@a.pl",
      phone_number: "123123123",
      customer_number: "1027657",
      state: "created",
    },
    vehicle: {
      id: 2,
      plate_number: "DEF-123",
      region: null,
      vin: "TESTVINVWGOLF2016",
      state: "created",
      customer_number: "1027657",
      mileage: 49002,
    },
    advisor: {
      name: "",
      photo: null,
      phone: null,
    },
    date: null,
  },
  uber: {},
  availableServices: {},
  availableRecalls: [],
  additionalServices: [],
  decisionTrees: [],
  decisionTreeResults: [],
  progress: 0,
  lastRequest: null,
  isVehicleSynced: true,
  isInitialized: false,
  isCustomerPagesInitialized: false,
  customerProvidedPhoneNumber: false,
  isAppointmentFinalized: false,
  isRescheduleLoading: false,
  bookingStepProps: null,
  bookingStepInputProps: null,
  questionnairesId: null,
  questionnaires: [],
  questionnairesError: null,
  questionnairesAnswers: {},
  questionnairesAnswersError: null,
  reschedulingError: null,
};

/* eslint-disable no-case-declarations */

const chat = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EXIT:
      return {
        ...state,
      };
    case BACK_FROM_CHAT:
      return {
        ...state,
        isInitialized: false,
      };
    case chatActions.SET_LAST_REQUEST:
      return {
        ...state,
        lastRequest: payload.request,
      };
    case chatActions.CHANGE_STEP:
      return {
        ...state,
        history: state.history.concat({
          component: STEPS[payload.step].step,
          props: payload.props || {},
          id: state.lastId + 1,
        }),
        currentInput: STEPS[payload.step].input && {
          component: STEPS[payload.step].input,
          props: {
            stepId: state.lastId + 1,
            ...payload.inputProps,
          },
        },
        progress: toFixedPrecision(
          (STEPS[payload.step].progress * 100) / MAX_PROGRESS,
          2,
        ),
        lastId: state.lastId + 1,
      };
    case chatActions.UPDATE_CURRENT_STEP:
      if (!state.isInitialized)
        return {
          ...state,
        };
      return {
        ...state,
        history: state.history.slice(0, -1).concat({
          ...state.history[state.history.length - 1],
          props: {
            ...state.history[state.history.length - 1].props,
            ...payload.props,
          },
        }),
        currentInput: state.currentInput && {
          ...state.currentInput,
          props: {
            ...state.currentInput.props,
            ...payload.inputProps,
          },
        },
      };
    case chatActions.SET_CHAT_LOADING_STATE:
      return {
        ...state,
        isLoading: payload.value,
        loadingStateDelay: payload.delay,
      };
    case chatActions.INITIALIZE_CHAT:
      return {
        ...state,
        isInitialized: true,
      };
    case chatActions.INITIALIZE_CUSTOMER_PAGES:
      return {
        ...state,
        isCustomerPagesInitialized: payload,
      };
    case chatActions.SET_CUSTOMER_PROVIDED_PHONE_NUMBER:
      return {
        ...state,
        customerProvidedPhoneNumber: payload.value,
      };

    case FETCH_APPOINTMENT_DETAILS_SUCCESS:
      console.log(payload);

      const {
        menu_items: menuItems,
        bookings_menu_items: bookingsMenuItems,
        decision_tree_results: decisionTreeResults,
        client_waiting,
        pickup_time_variance,
        mobile_technician_time_variance,
        booking_status,
      } = payload;

      const { maintenance = [], concern = [] } = menuItems || {};

      return {
        ...state,
        isVehicleSynced: payload.vehicle.state !== NOT_SYNCED,
        isPickupJobCheckin:
          payload.jobs.length &&
          !!payload.jobs.find((job) => job.aasm_state !== "cancelled" && job.job_type === "pickup"),
        order: {
          ...state.order,
          appointment: {
            ...payload,
          },
          date: payload.appointment_datetime,
          client_waiting,
          services: { maintenance, concern },
          bookingsMenuItems,
          decisionTreeResults,
          customer: {
            id: payload.customer.id,
            name: payload.customer.first_name,
            lastName: payload.customer.last_name,
            phone_number: [
              state.order.customer && state.order.customer.phoneNumber,
              payload.customer.phone_number,
            ]
              .filter((number) => number !== "0")
              .pop(),
            customer_number: payload.customer.customer_number,
          },
          vehicle: {
            ...payload.vehicle,
            make: payload.vehicle_set.make,
            model: payload.vehicle_set.model,
            year: payload.vehicle_set.model_year,
            mileage: payload.vehicle.mileage,
          },
          jobs: payload.jobs,
          lineItems: payload.line_items || [],
          advisor: payload.service_advisor && {
            name: payload.service_advisor.name,
            photo: payload.service_advisor.photo.url,
            phone: "",
          },
          qrCode: payload.qr_code && payload.qr_code.url,
          source: "customer",
        },
        timeVariance: {
          pickUp: pickup_time_variance,
          mobileTechnician: mobile_technician_time_variance,
        },
        status: booking_status,
      };
    case chatActions.RESCHEDULE:
      return {
        ...state,
        isRescheduleLoading: true,
        reschedulingError: null,
      };

    case chatActions.RESCHEDULE_PICKUP_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          jobs: state.order.jobs.map((job) =>
            job.id === payload.id ? payload : job,
          ),
        },
      };
    case chatActions.RESCHEDULE_SUCCESS:
      return {
        ...state,
        reschedulingError: null,
        isRescheduleLoading: false,
      };
    case chatActions.RESCHEDULE_FAILURE:
      return {
        ...state,
        reschedulingError: payload,
        isRescheduleLoading: false,
      };
    case chatActions.FETCH_REPAIR_ORDER_DETAILS:
      return {
        ...state,
        order: {
          ...state.order,
          repair_order: payload.repair_order_number,
        },
      };
    case chatActions.FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS:
      return {
        ...state,
        makeModelYearMap: payload,
      };
    case chatActions.SET_CURRENT_VEHICLE:
      return {
        ...state,
        order: {
          ...state.order,
          vehicle: payload,
        },
      };
    case chatActions.SET_MILEAGE:
      return {
        ...state,
        order: {
          ...state.order,
          vehicle: {
            ...state.order.vehicle,
            mileage: payload.mileage,
          },
        },
      };
    case chatActions.FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        availableServices: {
          maintenance: payload.maintenance || [],
          concern: payload.concern || [],
        },
      };
    case chatActions.REMOVE_SERVICE:
      return {
        ...state,
        order: {
          ...state.order,
          services: {
            maintenance: removeService(
              state.order.services.maintenance,
              payload.id,
            ),
            concern: removeService(state.order.services.concern, payload.id),
          },
          bookingsMenuItems: removeService(
            state.order.bookingsMenuItems,
            payload.id,
          ),
          additionalServices: removeService(
            state.order.additionalServices,
            payload.id,
          ),
          selectedRecalls: removeService(
            state.order.selectedRecalls,
            payload.id,
          ),
        },
      };
    case chatActions.REMOVE_LINE_ITEM:
      return {
        ...state,
        order: {
          ...state.order,
          services: removeCorrespondingServices(
            state.order.services,
            state.order.lineItems,
            payload.id,
          ),
          lineItems: removeService(state.order.lineItems, payload.id),
        },
      };
    case chatActions.ADD_SERVICES:
      return {
        ...state,
        order: {
          ...state.order,
          services: {
            maintenance: (
              (state.order.services || {}).maintenance || []
            ).concat(payload.maintenance),
            concern: ((state.order.services || {}).concern || []).concat(
              payload.concern,
            ),
          },
        },
      };
    case chatActions.FETCH_DECISION_TREES:
      return {
        ...state,
        decisionTrees: payload.map((decisionTree) => ({
          ...decisionTree,
          serviceId: decisionTree.menu_item.id,
        })),
      };
    case INITIALIZE_DECISION_TREE:
      return {
        ...state,
        decisionTreeResults: state.decisionTreeResults.concat({
          serviceId: payload.serviceId,
          conversation: [
            {
              text: payload.intro,
              source: SOURCE_CONCIERGE,
            },
          ],
        }),
      };
    case UPDATE_DECISION_TREE:
      return {
        ...state,
        decisionTreeResults: [
          ...state.decisionTreeResults.slice(0, -1),
          {
            ...state.decisionTreeResults[state.decisionTreeResults.length - 1],
            conversation: [
              ...state.decisionTreeResults[state.decisionTreeResults.length - 1]
                .conversation,
              {
                text: payload.text,
                source: payload.source,
              },
            ],
          },
        ],
      };
    case SET_DECISION_TREE_DESCRIPTION:
      return {
        ...state,
        decisionTreeResults: [
          ...state.decisionTreeResults.slice(0, -1),
          {
            ...state.decisionTreeResults[state.decisionTreeResults.length - 1],
            description: payload.description,
          },
        ],
      };
    case chatActions.SET_ADDITIONAL_NOTES:
      return {
        ...state,
        order: {
          ...state.order,
          additionalNotes: payload.notes,
        },
      };
    case chatActions.SET_ADDITIONAL_SERVICES:
      return {
        ...state,
        additionalServices: payload && payload.extension,
        order: {
          ...state.order,
          additionalServices: [],
        },
      };
    case chatActions.CLEAR_ADDITIONAL_SERVICES:
      return {
        ...state,
        order: {
          ...state.order,
          additionalServices: [],
        },
      };
    case chatActions.CLEAR_SELECTED_RECALLS:
      return {
        ...state,
        order: {
          ...state.order,
          selectedRecalls: [],
        },
      };
    case chatActions.TOGGLE_EXTENSION:
      return {
        ...state,
        order: {
          ...state.order,
          additionalServices: toggleService(
            state.order.additionalServices,
            payload.service,
          ),
        },
      };
    case chatActions.SET_PRESELECTED_RECALLS: {
      const { bookingsMenuItems } = state.order;
      const { preselectedRecalls } = payload;

      return {
        ...state,
        order: {
          ...state.order,
          bookingsMenuItems: bookingsMenuItems.filter((bmi) =>
            preselectedRecalls.find((recall) => bmi.recall_id === recall.id),
          ),
          preselectedRecalls,
        },
      };
    }
    case chatActions.SET_AVAILABLE_RECALLS: {
      return {
        ...state,
        availableRecalls: payload.recalls,
        order: {
          ...state.order,
          selectedRecalls: [],
        },
      };
    }
    case chatActions.TOGGLE_RECALL:
      return {
        ...state,
        order: {
          ...state.order,
          selectedRecalls: toggleService(
            state.order.selectedRecalls,
            payload.service,
          ),
        },
      };
    case chatActions.UPDATE_SIGNATURE:
      return {
        ...state,
        order: {
          ...state.order,
          signature: payload.signature,
        },
      };
    case chatActions.SET_PHONE_NUMBER:
      return {
        ...state,
        order: {
          ...state.order,
          preferredPhoneNumber: payload.preferredPhoneNumber,
        },
      };
    case chatActions.SET_CLIENT_WAITING:
      return {
        ...state,
        order: {
          ...state.order,
          clientWaiting: payload.isWaiting,
        },
      };
    case chatActions.SET_AVAILABLE_TRANSPORTS:
      return {
        ...state,
        availableTransports: payload,
      };
    case chatActions.SET_TRANSPORT:
      return {
        ...state,
        selectedTransport: payload.transport,
      };
    case chatActions.SET_CUSTOMER:
      return {
        ...state,
        order: {
          ...state.order,
          customer: {
            id: payload.id,
            name: payload.first_name,
            lastName: payload.last_name,
            phoneNumber: payload.phone_number,
            customerNumber: payload.customer_number,
          },
        },
      };
    case chatActions.FETCH_UBER_DETAILS:
      return {
        ...state,
        uber: payload.details,
      };
    case chatActions.UPDATE_BOOKING_STEP: {
      return {
        ...state,
        bookingStepProps: { ...payload.props },
        bookingStepInputProps: {
          ...payload.inputProps,
        },
      };
    }
    case chatActions.UPDATE_APPOINTMENT_DATETIME: {
      return {
        ...state,
        order: {
          ...state.order,
          date: payload,
        },
      };
    }
    case chatActions.FINALIZE_APPOINTMENT: {
      return {
        ...state,
        isAppointmentFinalized: payload,
      };
    }
    case chatActions.FETCH_QUESTIONNAIRES_SUCCSESS: {
      return {
        ...state,
        questionnairesId: payload.id,
        questionnaires: payload.data,
        questionnairesError: null,
      };
    }
    case chatActions.FETCH_QUESTIONNAIRES_ERROR: {
      return {
        ...state,
        questionnairesId: null,
        questionnaires: [],
        questionnairesError: payload,
      };
    }
    case chatActions.FETCH_QUESTIONNAIRES_ANSWERS_SUCCSESS: {
      return {
        ...state,
        questionnairesAnswers: payload[0]?.response || {},
        questionnairesAnswersError: null,
      };
    }
    case chatActions.FETCH_QUESTIONNAIRES_ANSWERS_ERROR: {
      return {
        ...state,
        questionnairesAnswers: {},
        questionnairesAnswersError: payload,
      };
    }
    case chatActions.ADD_QUESTIONNAIRES_ANSWERS_SUCCSESS: {
      return {
        ...state,
        questionnairesAnswers: payload.response,
        questionnairesAnswersError: null,
      };
    }
    case chatActions.ADD_QUESTIONNAIRES_ANSWERS_ERROR: {
      return {
        ...state,
        questionnairesAnswers: {},
        questionnairesAnswersError: payload,
      };
    }
    default:
      return state;
  }
};
/* eslint-enable no-case-declarations */

export default chat;
