import * as moment from "moment-timezone";
import {
  buildUrl,
  makeFloat,
  percentageDiscount,
  shortenString,
  sum,
} from "shared/utils";
import {
  appAppointmentGuidSelector,
  appAppointmentSlotsSelector,
  appDealershipAddressSelector,
  appDealershipEmailSelector,
  appDealershipLocationSelector,
  appDealershipNameSelector,
  appDealershipPhoneSelector,
  appDealershipSlugSelector,
  appDealershipTimeZoneSelector,
  appJobSlotsSelector,
  appMobileTechnicianSlotsSelector,
} from "./app-selectors";

export const servicesSelector = (state) => ({
  maintenance: state.chat.order.services.maintenance || [],
  concern: state.chat.order.services.concern || [],
  extensions: state.chat.order.additionalServices || [],
  recalls: state.chat.order.selectedRecalls || [],
  bookingsMenuItems: state.chat.order.bookingsMenuItems || [],
});

export const allServicesSelector = (state) =>
  servicesSelector(state)
    .maintenance.concat(servicesSelector(state).concern)
    .concat(servicesSelector(state).extensions)
    .concat(servicesSelector(state).recalls)
    .concat(servicesSelector(state).bookingsMenuItems);

const filterServices = (services, lineItems) =>
  services.filter((service) =>
    lineItems.every((lineItem) => lineItem.name !== service.name),
  );

export const chatLineItemsSelector = (state) => state.chat.order.lineItems;
export const chatVehicleId = (state) => state.chat.order.vehicle.id;

export const filteredServicesSelector = (state) => {
  const { maintenance, concern, extensions, recalls, bookingsMenuItems } =
    servicesSelector(state);
  const lineItems = chatLineItemsSelector(state);

  return {
    maintenance: filterServices(maintenance, lineItems),
    concern: filterServices(concern, lineItems),
    extensions: filterServices(extensions, lineItems),
    recalls: filterServices(recalls, lineItems),
    bookingsMenuItems: bookingsMenuItems.filter((item) => !item.menu_item_id),
  };
};
export const filteredLineItemsSelector = (state) => {
  const { maintenance, concern, extensions, recalls } = servicesSelector(state);

  const lineItems = chatLineItemsSelector(state);
  return filterServices(lineItems, [
    ...maintenance,
    ...concern,
    ...extensions,
    ...recalls,
  ]);
};

export const allFilteredServicesSelector = (state) =>
  filteredServicesSelector(state)
    .maintenance.concat(filteredServicesSelector(state).concern)
    .concat(filteredServicesSelector(state).extensions)
    .concat(filteredServicesSelector(state).recalls)
    .concat(filteredServicesSelector(state).bookingsMenuItems);

export const chatOrderSelector = (state) => state.chat.order;
export const chatHistorySelector = (state) => state.chat.history;
export const chatLoadingSelector = (state) => state.chat.isLoading;
export const chatInputSelector = (state) => state.chat.currentInput;
export const chatDecisionTreeResultsSelector = (state) =>
  state.chat.decisionTreeResults;
export const chatCustomerNameSelector = (state) =>
  state.chat.order.customer.name;
export const chatCustomerIdSelector = (state) => state.chat.order.customer.id;
export const chatAdditionalNotesSelector = (state) =>
  state.chat.order.additionalNotes;
export const chatDecisionTreesSelector = (state) => state.chat.decisionTrees;
export const chatAdditionalServicesSelector = (state) =>
  state.chat.additionalServices;
export const chatSelectedExtensionsSelector = (state) =>
  state.chat.order.additionalServices;
export const chatCurrentVehicleSelector = (state) => state.chat.order.vehicle;
export const chatCurrentCustomerSelector = (state) => state.chat.order.customer;
export const chatAvailableRecallsSelector = (state) =>
  state.chat.availableRecalls;
export const chatSelectedRecallsSelector = (state) =>
  state.chat.order.selectedRecalls;
export const chatSignatureSelector = (state) => state.chat.order.signature;
export const chatPhoneNumberSelector = (state) =>
  state.chat.order.customer.phone_number;
export const chatAvailableTransportsSelector = (state) =>
  state.chat.availableTransports;
export const chatCustomerWaitingSelector = (state) =>
  state.chat.order.isWaiting;
export const chatLoadingStateDelaySelector = (state) =>
  state.chat.loadingStateDelay;
export const chatAppointmentIdSelector = (state) =>
  state.chat.order.appointment.id || "";
export const chatAppointmentNumberSelector = (state) =>
  state.chat.order.appointment.appointment_number || "";
export const chatAppointmentDateSelector = (state) => state.chat.order.date;
export const chatAppointmentQrCodeSelector = (state) => state.chat.order.qrCode;
export const chatMakeModelYearMapSelector = (state) =>
  state.chat.makeModelYearMap;
export const chatLastRequestSelector = (state) => state.chat.lastRequest;
export const chatIsVehicleSyncedSelector = (state) =>
  state.chat.isVehicleSynced;
export const chatIsInitializedSelector = (state) => state.chat.isInitialized;
export const chatIsCustomerPagesInitializedSelector = (state) =>
  state.chat.isCustomerPagesInitialized;
export const chatIsAppointmentFinalizedSelector = (state) =>
  state.chat.isAppointmentFinalized;
export const chatVehiclesSelector = (state) => state.chat.vehicles;
export const chatBookingNotFoundSelector = (state) =>
  typeof state.chat.order.appointment === "undefined";
export const chatCustomerProvidedPhoneNumberSelector = (state) =>
  state.chat.customerProvidedPhoneNumber;
export const chatTotalPriceSelector = (state) =>
  Number(sum(allServicesSelector(state), "fee"));
export const chatFilteredServicesPriceSelector = (state) =>
  sum(allFilteredServicesSelector(state), "fee");
export const chatLineItemsPriceSelector = (state) =>
  +chatLineItemsSelector(state)
    .reduce((total, item) => total + parseFloat(item.fee), 0.0)
    .toFixed(2);

export const calcAppointmentTimeHours = (state) => {
  const lineItems = filteredLineItemsSelector(state);
  const totalMenuItemHours = allServicesSelector(state)
    .reduce((total, service) => total + +service.allocated_labor_time, 0)
    .toFixed(1);
  const totalHours = lineItems
    .reduce(
      (total, item) => total + parseFloat(item.labor_hours),
      +totalMenuItemHours,
    )
    .toFixed(1);
  return totalHours;
};

export const chatBookingSelector = (state) => {
  const { order, isPickupJobCheckin } = state.chat;
  const allServices = allServicesSelector(state);
  const packageServices = allServices.filter((s) => s.package_items);
  // const lineItems = filteredLineItemsSelector(state);
  // const services = servicesSelector(state);
  const lineItems = chatLineItemsSelector(state);
  const services = filteredServicesSelector(state);
  const totalHours = calcAppointmentTimeHours(state);
  const timeLabel = totalHours > 1 ? "hours" : "hour";
  const { make, model } = order.vehicle;
  const modelYear = order.vehicle.year;
  const vehicle = `${make} ${model}, ${modelYear}`;
  const totalPriceBeforeDiscount = makeFloat(
    sum(allServices, "fee") + sum(packageServices, "saving"),
  );
  const totalPrice =
    Number(chatLineItemsPriceSelector(state)) +
    Number(chatFilteredServicesPriceSelector(state));
  const discount = percentageDiscount(totalPriceBeforeDiscount, totalPrice);
  const pickupJob = order.jobs.find((job) => job.aasm_state !== "cancelled" && job.job_type === "pickup");

  return {
    vehicle,
    services,
    date: order.date,
    isPickupJobCheckin,
    jobDate:
      pickupJob &&
      moment(pickupJob.collection_time).format("dddd, MMMM DD YYYY, h:mm A"),
    jobLocation: pickupJob && pickupJob.location,
    customer: `${order.customer.name} ${order.customer.lastName}`,
    totalPrice,
    totalPriceBeforeDiscount,
    discount,
    totalTime: `${totalHours} ${timeLabel}`,
    totalHours,
    lineItems,
    advisor: order.advisor,
  };
};

export const chatIsCheckInAvailableSelector = (state) => {
  const date = state.chat.order.date;
  const tz = appDealershipTimeZoneSelector(state);
  if (!date) return;
  const momentDate = moment.tz(date, "dddd, MMMM DD YYYY, h:mm A", tz);
  const diff = momentDate.diff(moment.tz(tz), "hours");
  return diff <= 24 && diff >= 0;
};

export const customerPageSelector = (state) => {
  const { order, isPickupJobCheckin, status } = state.chat;
  const { make, model, year, image } = order.vehicle;
  const vehicle = `${make} ${model}, ${year}`;
  const date = order.date;
  const guid = appAppointmentGuidSelector(state);
  const tz = appDealershipTimeZoneSelector(state);
  const momentDate = moment.tz(date, "dddd, MMMM DD YYYY, h:mm A", tz);
  const totalHours = calcAppointmentTimeHours(state);
  const momentUTCDate = moment.parseZone(momentDate.format()).utc();
  const dateIn = momentUTCDate.format("YYYYMMDD[T]HHmm00[Z]");
  const dateOut = momentUTCDate
    .add(totalHours, "h")
    .format("YYYYMMDD[T]HHmm00[Z]");
  const { lat, lng } = appDealershipLocationSelector(state);
  const address = appDealershipAddressSelector(state);
  const dealershipPhone = appDealershipPhoneSelector(state);
  const dealershipEmail = appDealershipEmailSelector(state);
  const location = `https://maps.google.com/?q=${lat},${lng}`;
  const calendarTitle = `Service reservation for ${vehicle}`;
  const calendarText = `
  Time: ${date}
  Location: ${location}

  You can edit here: ${window.location.origin}/${appDealershipSlugSelector(
    state,
  )}${guid ? `guid=${guid}` : ""}
    `;

  const calendarLink = buildUrl(
    dateIn,
    dateOut,
    calendarTitle,
    calendarText,
    address,
  );
  const pickupJob = order.jobs.find((job) => job.aasm_state !== "cancelled" && job.job_type === "pickup");

  return {
    vehicleImage: image && image.url,
    vehicle,
    advisor: order.advisor,
    dealershipPhone,
    dealershipEmail,
    date,
    isPickupJobCheckin,
    jobDate:
      pickupJob &&
      moment(pickupJob.collection_time).format("dddd, MMMM DD YYYY, h:mm A"),
    calendarLink,
    isCheckInAvailable: chatIsCheckInAvailableSelector(state),
    status,
  };
};

export const rescheduleSelector = (state) => {
  const isPickup = chatIsPickupJobCheckinSelector(state);
  const isMobileTechnician = chatIsMobileTechnicianJobCheckinSelector(state);
  const datetime = chatAppointmentDateSelector(state);
  const appointmentSlots = appAppointmentSlotsSelector(state);
  const jobSlots = appJobSlotsSelector(state);
  const mobileTechnicianSlots = appMobileTechnicianSlotsSelector(state);

  if (isPickup) {
    return {
      isPickup,
      datetime,
      slots: jobSlots,
    };
  }

  if (isMobileTechnician) {
    return {
      isMobileTechnician,
      datetime,
      slots: mobileTechnicianSlots,
    };
  }

  return {
    isPickup,
    datetime,
    slots: appointmentSlots,
  };
};

export const chatPickupJobIdSelector = (state) => {
  const isPickupJobCheckin = chatIsPickupJobCheckinSelector(state);
  return isPickupJobCheckin
    ? state.chat.order.jobs.find((job) => job.aasm_state !== "cancelled" && job.job_type === "pickup").id
    : null;
};

export const chatMobileTechnicianJobIdSelector = (state) => {
  const isMobileTechnician = chatIsMobileTechnicianJobCheckinSelector(state);
  return isMobileTechnician
    ? state.chat.order.jobs.find((job) => job.job_type === "mobile_technician").id
    : null;
};

export const chatMobileTechnicianJobSelector = (state) => {
  return state.chat.order.jobs.find(
    (job) =>
      job.aasm_state !== "cancelled" && job.job_type === "mobile_technician",
  );
};

export const chatPickupJobSelector = (state) => {
  return state.chat.order.jobs.find(
    (job) =>
      job.aasm_state !== "cancelled" && job.job_type === "pickup",
  );
};

export const chatIsPickupJobCheckinSelector = (state) =>
  state.chat.isPickupJobCheckin;
export const chatIsMobileTechnicianJobCheckinSelector = (state) => {
  return !!state.chat.order.jobs.find(
    (job) => job.job_type === "mobile_technician",
  );
};
export const chatAllAvailableServicesSelector = (state) =>
  state.chat.availableServices;
export const chatAvailableServicesSelector = (state) => ({
  maintenance: state.chat.availableServices.maintenance.filter((service) => {
    const selectedMaintenance =
      (state.chat.order.services || {}).maintenance || [];
    const packageItems = [].concat(
      ...selectedMaintenance.map((s) => s.package_items),
    );

    return !selectedMaintenance
      .concat(packageItems)
      .map((s) => s.id)
      .includes(service.id);
  }),
  concern: state.chat.availableServices.concern.filter((service) => {
    const selectedConcern = (state.chat.order.services || {}).concern || [];
    const packageItems = [].concat(
      ...selectedConcern.map((s) => s.package_items),
    );

    return !selectedConcern
      .concat(packageItems)
      .map((s) => s.id)
      .includes(service.id);
  }),
});

export const chatServiceIdsSelector = (state) =>
  Object.values(state.chat.order.services).reduce(
    (arr, elem) => [...arr, ...elem.map((e) => e.id)],
    [],
  );

export const chatUberSelector = (state) => {
  const { uber } = state.chat;
  const vehicle = uber.vehicle && `${uber.vehicle.make} ${uber.vehicle.model}`;

  return {
    driverName: uber.driver && uber.driver.name,
    driverPicture: uber.driver && uber.driver.picture_url,
    driverPhone: uber.driver && uber.driver.phone_number,
    time: uber.eta,
    vehicle,
  };
};

export const chatPrintingSummarySelector = (state) => {
  const dealershipInfo = `${appDealershipNameSelector(
    state,
  )}, ${appDealershipAddressSelector(state)}`;
  const filteredServices = allFilteredServicesSelector(state);
  /* eslint-disable no-useless-escape */
  const lineItems = chatLineItemsSelector(state);
  const itemsSummary = filteredServices
    .concat(lineItems)
    .map((s) => `${s.name}: ${makeFloat(s.fee)} USD`)
    .join("\n");
  const totalValue =
    chatLineItemsPriceSelector(state) +
    chatFilteredServicesPriceSelector(state);

  const summary = `
     This is your confirmation.
     ${dealershipInfo}
     ${moment(new Date()).format("MM/DD/YYYY h:mm a")}
     Repair Order number: ${state.chat.order.repair_order}
     Selected Services:
     ${itemsSummary}
     Total: ${makeFloat(totalValue)} USD plus applicable fees and taxes
    `;
  const summaryLines = summary.split("\n");

  return summaryLines
    .map((el) => {
      const textLines = [];
      let stringToCut = el;
      let lineStartIndex = 0;
      do {
        const textLine = shortenString(stringToCut);
        lineStartIndex += textLine.length;
        textLines.push(textLine.trimLeft());
        stringToCut = el.substr(lineStartIndex, el.length);
      } while (stringToCut !== "");
      return textLines;
    })
    .reduce((acc, val) => acc.concat(val), [])
    .join("\n");
  /* eslint-enable */
};

export const chatBookingStepSelector = (state) => state.chat.bookingStepProps;
export const chatBookingStepInputSelector = (state) =>
  state.chat.bookingStepInputProps;

export const chatMobileTechnicianTimeVarianceSelector = (state) =>
  state.chat.timeVariance.mobileTechnician;
export const chatPickUpTimeVarianceSelector = (state) =>
  state.chat.timeVariance.pickUp;

export const chatQuestionnairesId = (state) => state.chat.questionnairesId;
export const chatQuestionnaires = (state) => state.chat.questionnaires;
export const chatQuestionnairesAnswers = (state) =>
  state.chat.questionnairesAnswers;
export const chatReschedulingErrorSelector = (state) =>
  state.chat.reschedulingError;
