import * as moment from "moment-timezone";
import {
  addTransport,
  createQuestionnairesAnswers,
  fetchAdditionalServices,
  fetchAppointmentByGuid,
  fetchAppointmentByPhoneNumber,
  fetchAppointmentSlots,
  fetchAvailableTransports,
  fetchCancelAppointment,
  fetchCancelJob,
  fetchDecisionTrees,
  fetchJobSlots,
  fetchListOfAvailableQuestionnaires,
  fetchMobileTechicianSlots,
  fetchRecallsForCheckin,
  fetchServices,
  fetchUpdateAppointment,
  fetchUpdateJob,
  fetchlistOfAvailableQuestionnairesAnswers,
  sendSignature,
  startCheckIn,
} from "shared/api";
import {
  ADDITIONAL_SERVICES_STEP,
  BOOKING_CONFIRMATION_STEP,
  BOOKING_SUMMARY_STEP,
  ERROR_STEP,
  FINAL_STEP,
  GREETING_STEP,
  KEY_HANDOVER_STEP,
  LOANER_STEP,
  SHUTTLE_STEP,
  SIGNATURE_STEP,
  TRANSPORT_SELECTION_STEP,
  UBER_STEP,
} from "shared/constants/checkin-steps";
import { DELAY_500, DELAY_1000, DELAY_2000 } from "shared/constants/delays";
import { formatPhoneNumber } from "shared/utils";
import { initializeDecisionTree } from "store/actions/decision-trees-actions";
import {
  appAppointmentGuidSelector,
  appCheckinIdSelector,
  appDealershipIdSelector,
  appDealershipTimeZoneSelector,
  appJobSlotsSelector,
} from "store/selectors/app-selectors";
import {
  chatAdditionalServicesSelector,
  chatAppointmentIdSelector,
  chatBookingSelector,
  chatCurrentVehicleSelector,
  chatCustomerIdSelector,
  chatDecisionTreesSelector,
  chatIsMobileTechnicianJobCheckinSelector,
  chatIsPickupJobCheckinSelector,
  chatPickupJobIdSelector,
  chatLastRequestSelector,
  chatMobileTechnicianJobSelector,
  chatOrderSelector,
  chatPhoneNumberSelector,
  chatQuestionnairesId,
  chatServiceIdsSelector,
  chatSignatureSelector,
  chatVehicleId,
  chatMobileTechnicianJobIdSelector,
  chatPickupJobSelector,
} from "store/selectors/chat-selectors";
import { FETCH_APPOINTMENT_SLOTS, FETCH_JOB_SLOTS, FETCH_MOBILE_TECHNICIAN_SLOTS } from "./app-actions";
import { FETCH_APPOINTMENT_DETAILS_ERROR, changeWelcomeScreenType } from "./welcome-actions";

export const STORE_DEALERSHIP_SLUG = "STORE_DEALERSHIP_SLUG";
export const CHANGE_STEP = "CHANGE_STEP";
export const UPDATE_CURRENT_STEP = "UPDATE_CURRENT_STEP";
export const UPDATE_BOOKING_STEP = "UPDATE_BOOKING_STEP";
export const SET_CHAT_LOADING_STATE = "SET_CHAT_LOADING_STATE";
export const INITIALIZE_CHAT = "INITIALIZE_CHAT";
export const INITIALIZE_CUSTOMER_PAGES = "INITIALIZE_CUSTOMER_PAGES";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const REMOVE_SERVICE = "REMOVE_SERVICE";
export const REMOVE_LINE_ITEM = "REMOVE_LINE_ITEM";
export const ADD_SERVICES = "ADD_SERVICES";
export const FETCH_DECISION_TREES = "FETCH_DECISION_TREES";
export const SET_ADDITIONAL_NOTES = "SET_ADDITIONAL_NOTES";
export const SET_ADDITIONAL_SERVICES = "SET_ADDITIONAL_SERVICES";
export const TOGGLE_EXTENSION = "TOGGLE_EXTENSION";
export const SET_AVAILABLE_RECALLS = "SET_AVAILABLE_RECALLS";
export const SET_PRESELECTED_RECALLS = "SET_PRESELECTED_RECALLS";
export const TOGGLE_RECALL = "TOGGLE_RECALL";
export const UPDATE_SIGNATURE = "UPDATE_SIGNATURE";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_CLIENT_WAITING = "SET_CLIENT_WAITING";
export const SET_AVAILABLE_TRANSPORTS = "SET_AVAILABLE_TRANSPORTS";
export const START_CHECKIN_SUCCESS = "START_CHECKIN_SUCCESS";
export const FETCH_VEHICLE_DETAILS = "FETCH_VEHICLE_DETAILS";
export const FETCH_REPAIR_ORDER_DETAILS = "FETCH_REPAIR_ORDER_DETAILS";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const FETCH_UBER_DETAILS = "FETCH_UBER_DETAILS";
export const SET_TRANSPORT = "SET_TRANSPORT";
export const FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS =
  "FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS";
export const SET_CURRENT_VEHICLE = "SET_CURRENT_VEHICLE";
export const SET_MILEAGE = "SET_MILEAGE";
export const SET_LAST_REQUEST = "SET_LAST_REQUEST";
export const SET_CUSTOMER_PROVIDED_PHONE_NUMBER =
  "SET_CUSTOMER_PROVIDED_PHONE_NUMBER";
export const CLEAR_ADDITIONAL_SERVICES = "CLEAR_ADDITIONAL_SERVICES";
export const CLEAR_SELECTED_RECALLS = "CLEAR_SELECTED_RECALLS";
export const CONVERT_APPOINTMENT_TO_RO = "CONVERT_APPOINTMENT_TO_RO";
export const UPDATE_APPOINTMENT_DATETIME = "UPDATE_APPOINTMENT_DATETIME";
export const FINALIZE_APPOINTMENT = "FINALIZE_APPOINTMENT";
export const FETCH_APPOINTMENT_ERROR = "FETCH_APPOINTMENT_ERROR";
export const SET_WELCOME_PAGE = "SET_WELCOME_PAGE";

export const RESCHEDULE = "RESCHEDULE";
export const RESCHEDULE_SUCCESS = "RESCHEDULE_SUCCESS";
export const RESCHEDULE_FAILURE = "RESCHEDULE_FAILURE";
export const RESCHEDULE_PICKUP_SUCCESS = "RESCHEDULE_PICKUP_SUCCESS";

export const LOANER_TRANSPORTATION_TYPE = "loaner";
export const SHUTTLE_TRANSPORTATION_TYPE = "shuttle";
export const UBER_TRANSPORTATION_TYPE = "uber";

export const FETCH_QUESTIONNAIRES_SUCCSESS = "FETCH_QUESTIONNAIRES_SUCCSESS";
export const FETCH_QUESTIONNAIRES_ERROR = "FETCH_QUESTIONNAIRES_ERROR";
export const FETCH_QUESTIONNAIRES_ANSWERS_SUCCSESS =
  "FETCH_QUESTIONNAIRES_ANSWERS_SUCCSESS";
export const FETCH_QUESTIONNAIRES_ANSWERS_ERROR =
  "FETCH_QUESTIONNAIRES_ANSWERS_ERROR";
export const ADD_QUESTIONNAIRES_ANSWERS_SUCCSESS =
  "ADD_QUESTIONNAIRES_ANSWERS_SUCCSESS";
export const ADD_QUESTIONNAIRES_ANSWERS_ERROR =
  "ADD_QUESTIONNAIRES_ANSWERS_ERROR";

export const setLastRequest = (request) => ({
  type: SET_LAST_REQUEST,
  payload: { request },
});

export const initializeStep = (step, props, inputProps) => ({
  type: CHANGE_STEP,
  payload: { step, props, inputProps },
});

export const setLoadingState = (value = true, delay = DELAY_500) => ({
  type: SET_CHAT_LOADING_STATE,
  payload: { value, delay },
});

export const replayLastRequest = () => (dispatch, getState) => {
  dispatch(setLoadingState());
  chatLastRequestSelector(getState())();
};

export const updateCurrentStep = (props, inputProps) => ({
  type: UPDATE_CURRENT_STEP,
  payload: { props, inputProps },
});
export const updateBookingStep = (props, inputProps) => ({
  type: UPDATE_BOOKING_STEP,
  payload: { props, inputProps },
});

export const initializeErrorStep = (error, canRetry) => (dispatch) => {
  console.error(error);
  const message = error instanceof Object ? error.message : error;
  dispatch(
    initializeStep(ERROR_STEP, { error: message, canRetry }, { canRetry }),
  );
  dispatch(setLoadingState(false));
};

export const updateAppointment = (state, date) => {
  const { order, decisionTreeResults } = state.chat;
  const orderDate = moment
    .tz(
      date || order.date,
      "dddd, MMMM DD YYYY, h:mm A",
      appDealershipTimeZoneSelector(state),
    )
    .format();

  const menu_item_comments = decisionTreeResults.map(
    ({ serviceId, description }) => ({
      id: serviceId,
      comment: description,
    }),
  );

  return fetchUpdateAppointment(
    appCheckinIdSelector(state),
    chatAppointmentIdSelector(state),
    appDealershipIdSelector(state),
    {
      ...order,
      date: orderDate,
    },
    [
      ...chatServiceIdsSelector(state),
      ...order.additionalServices.map((s) => s.id),
    ],
    menu_item_comments,
  );
};

export const rescheduleAppointment = (date, time) => (dispatch, getState) => {
  const appointmentDate = moment("" + date + time, "YYYY-MM-DD HH:mm").format(
    "dddd, MMMM DD YYYY, h:mm A",
  );
  dispatch({ type: RESCHEDULE });
  updateAppointment(getState(), appointmentDate);
};

export const rescheduleRemote = (date, time) => async (dispatch, getState) => {
  const checkinId = appCheckinIdSelector(getState());
  const isPickup = chatIsPickupJobCheckinSelector(getState());
  const jobId = isPickup ? chatPickupJobIdSelector(getState()) : chatMobileTechnicianJobIdSelector(getState());
  const formatDate = (d, t) =>
    moment
      .tz("" + d + t, "YYYY-MM-DD HH:mm", appDealershipTimeZoneSelector(getState()))
      .format();
  const pickupDate = formatDate(date, time);
  dispatch({ type: RESCHEDULE });
  try {
    const updatedJob = await fetchUpdateJob(
      checkinId,
      jobId,
      pickupDate,
    );
    dispatch({
      type: RESCHEDULE_PICKUP_SUCCESS,
      payload: updatedJob,
    });
  } catch (error) {
    console.error(error);
  }
};

export const cancelAppointment = () => (dispatch, getState) => {
  dispatch(changeWelcomeScreenType("cancelled"));
  dispatch({
    type: INITIALIZE_CUSTOMER_PAGES,
    payload: false,
  });
  const state = getState();
  fetchCancelAppointment(
    appCheckinIdSelector(state),
    chatAppointmentIdSelector(state),
  )
    .then(() => {
      if (chatIsPickupJobCheckinSelector(state))
        return fetchCancelJob(
          appCheckinIdSelector(state),
          chatPickupJobIdSelector(state),
        );
    })
    .catch((err) => console.error(err));
};

export const confirmBookingServices = () => (dispatch, getState) => {
  // #todo compare with prev state and then update
  // const state = getState();
  // console.log([...chatServiceIdsSelector(state), ...state.chat.order.additionalServices.map(s => s.id)]);
  updateAppointment(getState());
};

const finalizeAppointment = () => (dispatch, getState) => {
  const request = () =>
    updateAppointment(getState())
      .then(() =>
        dispatch({
          type: FINALIZE_APPOINTMENT,
          payload: true,
        }),
      )
      .catch((error) => dispatch(initializeErrorStep(error, true)));
  dispatch(setLastRequest(request));
  request();
};

export const initializeCheckIn =
  (dealershipSlug) => async (dispatch, getState) => {
    if (
      !dealershipSlug ||
      dealershipSlug === "null" ||
      dealershipSlug === "undefined"
    )
      throw new Error("no dealership specified");

    dispatch({
      type: STORE_DEALERSHIP_SLUG,
      payload: dealershipSlug,
    });
    try {
      const guid = appAppointmentGuidSelector(getState());
      const response = await startCheckIn(dealershipSlug, guid);
      dispatch({
        type: START_CHECKIN_SUCCESS,
        payload: response,
      });
      dispatch(setLoadingState(true));
      if (guid) {
        await fetchAppointmentByGuid(response.id, guid);
        dispatch(setLoadingState(false));
      }
    } catch (error) {
      dispatch({
        type: FETCH_APPOINTMENT_DETAILS_ERROR,
        payload: {
          sourceGuid: true,
          error,
        },
      });
    }
  };

export const phoneNumberAuth = (phone) => async (dispatch, getState) => {
  try {
    await fetchAppointmentByPhoneNumber(
      appCheckinIdSelector(getState()),
      phone,
    );
  } catch (error) {
    dispatch({
      type: FETCH_APPOINTMENT_ERROR,
      payload: error,
    });
    dispatch({ type: SET_WELCOME_PAGE });
  }
};

export const fetchAppointmentSlotsItems =
  (month, year) => async (dispatch, getState) => {
    const checkinId = appCheckinIdSelector(getState());
    const vehicleId = chatVehicleId(getState());
    const isPickup = chatIsPickupJobCheckinSelector(getState());
    const isMobileTechnician = chatIsMobileTechnicianJobCheckinSelector(getState());
    const mobileTechnicianJob = chatMobileTechnicianJobSelector(getState());
    const pickupTechnicianJob = chatPickupJobSelector(getState());

    try {

      if (!isMobileTechnician || !isPickup) {
        const slots = await fetchAppointmentSlots(
          checkinId,
          month,
          year,
        );
        dispatch({
          type: FETCH_APPOINTMENT_SLOTS,
          payload: slots,
        });
      }

      if (isPickup) {
        const jobSlots = await fetchJobSlots(
          checkinId,
          month,
          year,
          vehicleId,
          pickupTechnicianJob.location_area
        );

        dispatch({
          type: FETCH_JOB_SLOTS,
          payload: jobSlots,
        });
      }

      if (isMobileTechnician) {
        const mobileTechnicianSlots = await fetchMobileTechicianSlots(
          checkinId,
          month,
          year,
          vehicleId,
          mobileTechnicianJob.location_area,
        );

        dispatch({
          type: FETCH_MOBILE_TECHNICIAN_SLOTS,
          payload: mobileTechnicianSlots,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

export const inintializeCustomerPages =
  (appointmentDatetime) => async (dispatch, getState) => {
    const checkinId = appCheckinIdSelector(getState());
    const vehicleId = chatVehicleId(getState());
    const isPickup = chatIsPickupJobCheckinSelector(getState());
    const isMobileTechnician = chatIsMobileTechnicianJobCheckinSelector(getState());
    const mobileTechnicianJob = chatMobileTechnicianJobSelector(getState());
    const pickupTechnicianJob = chatPickupJobSelector(getState());

    try {
      if (!isMobileTechnician || !isPickup) {
        const slots = await fetchAppointmentSlots(
          checkinId,
          appointmentDatetime.getMonth() + 1,
          appointmentDatetime.getFullYear(),
        );

        dispatch({
          type: FETCH_APPOINTMENT_SLOTS,
          payload: slots,
        });
      }

      if (isPickup) {
        const jobSlots = await fetchJobSlots(
          checkinId,
          appointmentDatetime.getMonth() + 1,
          appointmentDatetime.getFullYear(),
          vehicleId,
          pickupTechnicianJob.location_area
        );

        dispatch({
          type: FETCH_JOB_SLOTS,
          payload: jobSlots,
        });
      }

      if (isMobileTechnician) {
        const mobileTechnicianSlots = await fetchMobileTechicianSlots(
          checkinId,
          appointmentDatetime.getMonth() + 1,
          appointmentDatetime.getFullYear(),
          vehicleId,
          mobileTechnicianJob.location_area,
        );

        dispatch({
          type: FETCH_MOBILE_TECHNICIAN_SLOTS,
          payload: mobileTechnicianSlots,
        });
      }
    } catch (error) {
      console.error(error);
    }
    try {
      const services = await fetchServices(
        appCheckinIdSelector(getState()),
        appDealershipIdSelector(getState()),
        chatCurrentVehicleSelector(getState()),
      );
      dispatch({
        type: FETCH_SERVICES_SUCCESS,
        payload: services,
      });
    } catch (error) {
      console.error(error);
    }
    dispatch({
      type: INITIALIZE_CUSTOMER_PAGES,
      payload: true,
    });
  };

export const initializeChat = () => async (dispatch, getState) => {
  dispatch({
    type: INITIALIZE_CHAT,
    payload: {},
  });
  dispatch(initializeStep(GREETING_STEP));
  dispatch(setLoadingState(true, DELAY_2000));
  const services = await fetchServices(
    appCheckinIdSelector(getState()),
    appDealershipIdSelector(getState()),
    chatCurrentVehicleSelector(getState()),
  );
  dispatch({
    type: FETCH_SERVICES_SUCCESS,
    payload: services,
  });
  dispatch(getListOfAvailableQuestionnaires());
  dispatch(getlistOfAvailableQuestionnairesAnswers());
  dispatch(setLoadingState(false));
  dispatch(initializeStep(BOOKING_CONFIRMATION_STEP));
};

export const initializeFinalStep = () => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState());
  dispatch(finalizeAppointment());
};

export const editBooking = () => (dispatch) => {
  dispatch(updateCurrentStep({ isEditing: true }));
  dispatch(updateBookingStep({ isEditing: true }));
};
export const undoEditBooking = () => (dispatch) => {
  dispatch(updateCurrentStep({ isEditing: false }));
  dispatch(updateBookingStep({ isEditing: false }));
};
export const openServiceSelector = (kind) => (dispatch) => {
  dispatch(updateCurrentStep({}, { serviceKind: kind }));
  dispatch(updateBookingStep({}, { serviceKind: kind }));
};
export const closeServiceSelector = () => (dispatch) => {
  dispatch(updateCurrentStep({}, { serviceKind: null }));
  dispatch(updateBookingStep({ isEditing: true }, { serviceKind: null }));
};
export const removeService = (id) => ({
  type: REMOVE_SERVICE,
  payload: { id },
});
export const removeLineItem = (id) => ({
  type: REMOVE_LINE_ITEM,
  payload: { id },
});
export const addServices = (newServices) => ({
  type: ADD_SERVICES,
  payload: newServices,
});

export const selectAdditionalServices =
  (selectedServices) => (dispatch, getState) => {
    if (selectedServices === null) {
      dispatch({
        type: CLEAR_ADDITIONAL_SERVICES,
      });
    }

    const checkinId = appCheckinIdSelector(getState());
    const request = () =>
      fetchRecallsForCheckin(checkinId).catch((error) =>
        dispatch(initializeErrorStep(error, true)),
      );

    dispatch(updateCurrentStep({ isComplete: true }));
    dispatch(setLoadingState());
    dispatch(setLastRequest(request));
    request();
  };

export const goToAdditionalServicesStep = () => (dispatch, getState) => {
  const additionalServices = chatAdditionalServicesSelector(getState());
  if (additionalServices.length) {
    dispatch(
      initializeStep(ADDITIONAL_SERVICES_STEP, {
        services: additionalServices,
      }),
    );
  } else {
    const request = () =>
      fetchAdditionalServices(
        appCheckinIdSelector(getState()),
        appDealershipIdSelector(getState()),
        chatCurrentVehicleSelector(getState()),
      )
        .then((payload) => {
          dispatch({
            type: SET_ADDITIONAL_SERVICES,
            payload,
          });
          const services = (payload.extension || []).filter((s) => s.fee);
          if (services.length) {
            dispatch(initializeStep(ADDITIONAL_SERVICES_STEP, { services }));
          } else {
            dispatch(selectAdditionalServices());
          }
        })
        .catch((error) => dispatch(initializeErrorStep(error, true)))
        .finally(() => dispatch(setLoadingState(false)));
    dispatch(setLastRequest(request));
    request();
  }
};

export const confirmBooking = () => (dispatch, getState) => {
  dispatch(
    updateCurrentStep({
      isComplete: true,
      isEditing: false,
    }),
  );
  dispatch(setLoadingState());
  const request = () =>
    fetchDecisionTrees(
      appCheckinIdSelector(getState()),
      chatServiceIdsSelector(getState()),
    )
      .then((response) => {
        if (response.length > 0) {
          dispatch(setLoadingState(false));
          dispatch({
            type: FETCH_DECISION_TREES,
            payload: response,
          });
          const firstTree = chatDecisionTreesSelector(getState())[0];

          const { decisionTreeResults } = chatOrderSelector(getState());
          const isFilled = (decisionTreeResults || []).find(
            (decisionTree) => decisionTree.menu_item_id === firstTree.serviceId,
          );

          setTimeout(
            () =>
              dispatch(
                initializeDecisionTree(
                  chatDecisionTreesSelector(getState())[0],
                  !!isFilled,
                ),
              ),
            DELAY_500,
          );
        } else {
          setTimeout(() => dispatch(goToAdditionalServicesStep()), DELAY_1000);
        }
      })
      .catch((error) => dispatch(initializeErrorStep(error, true)));
  dispatch(setLastRequest(request));
  request();
};

export const addNotes = (notes) => (dispatch) => {
  if (notes) {
    dispatch({
      type: SET_ADDITIONAL_NOTES,
      payload: { notes },
    });
  }
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState());
  dispatch(goToAdditionalServicesStep());
};

export const toggleExtension = (service) => ({
  type: TOGGLE_EXTENSION,
  payload: { service },
});

export const toggleRecall = (service) => ({
  type: TOGGLE_RECALL,
  payload: { service },
});

export const selectRecalls = (selectedRecalls) => (dispatch) => {
  if (selectedRecalls === null) {
    dispatch({ type: CLEAR_SELECTED_RECALLS });
  }
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(initializeStep(BOOKING_SUMMARY_STEP));
};

export const confirmOrder = () => (dispatch) => {
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(initializeStep(SIGNATURE_STEP));
};

export const updateSignature = (signature) => ({
  type: UPDATE_SIGNATURE,
  payload: { signature },
});

export const confirmSignature = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({}, { isComplete: true }));
  const request = () =>
    sendSignature(
      appCheckinIdSelector(getState()),
      chatAppointmentIdSelector(getState()),
      chatSignatureSelector(getState()),
    )
      .then(() => {
        dispatch(
          updateCurrentStep({
            isComplete: true,
            signature: chatSignatureSelector(getState()),
          }),
        );
        dispatch(initializeFinalStep());
      })
      .catch((error) => dispatch(initializeErrorStep(error, true)));
  dispatch(setLastRequest(request));
  request();
};

export const confirmNumber = (phoneNumber) => (dispatch, getState) => {
  const preferredPhoneNumber = formatPhoneNumber(
    phoneNumber || chatPhoneNumberSelector(getState()),
  );
  dispatch(
    updateCurrentStep({
      isComplete: true,
      preferredPhoneNumber,
    }),
  );
  dispatch({
    type: SET_PHONE_NUMBER,
    payload: { preferredPhoneNumber },
  });
  dispatch(initializeStep(KEY_HANDOVER_STEP));
};

export const confirmKeyHandover = () => (dispatch, getState) => {
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState(true, DELAY_500));
  const request = () =>
    fetchAvailableTransports(
      appCheckinIdSelector(getState()),
      Math.round(chatBookingSelector(getState()).totalPrice),
      chatBookingSelector(getState()).totalHours,
    )
      .then((response) => {
        dispatch(setLoadingState(false));
        dispatch({
          type: SET_AVAILABLE_TRANSPORTS,
          payload: response,
        });
        dispatch(initializeStep(TRANSPORT_SELECTION_STEP));
      })
      .catch((error) => dispatch(initializeErrorStep(error, true)));
  dispatch(setLastRequest(request));
  request();
};

export const paymentSuccess = () => (dispatch) => {
  dispatch(setLoadingState(true, DELAY_500));
  dispatch(updateCurrentStep({ isComplete: true }));
  dispatch(setLoadingState(false));
  dispatch(initializeStep(FINAL_STEP));
};

export const printingSuccess = () => (dispatch) => {
  dispatch(updateCurrentStep({}, { isComplete: true }));
};

export const printingFailure = (error) => initializeErrorStep(error);

export const selectWaiting = () => (dispatch) => {
  dispatch(
    updateCurrentStep({
      isConfirmed: true,
      confirmedWaiting: true,
    }),
  );
  dispatch({
    type: SET_CLIENT_WAITING,
    payload: { isWaiting: true },
  });
  dispatch(initializeFinalStep());
};

export const selectNotWaiting = () => (dispatch) => {
  dispatch({
    type: SET_CLIENT_WAITING,
    payload: { isWaiting: false },
  });
  dispatch(
    updateCurrentStep({
      isConfirmed: true,
      confirmedWaiting: false,
    }),
  );
};

export const selectNoTransport = () => (dispatch) => {
  dispatch(
    updateCurrentStep({
      hasSelectedTransport: true,
      needsTransport: false,
    }),
  );
  dispatch(initializeFinalStep());
};

export const selectNeedTransport = () =>
  updateCurrentStep({
    hasSelectedTransport: true,
    needsTransport: true,
  });

export const selectLoaner = () => (dispatch, getState) => {
  dispatch({
    type: SET_TRANSPORT,
    payload: {
      transport: LOANER_TRANSPORTATION_TYPE,
    },
  });
  const request = () =>
    addTransport(
      appCheckinIdSelector(getState()),
      chatAppointmentIdSelector(getState()),
      LOANER_TRANSPORTATION_TYPE,
    )
      .then(() => {
        dispatch(initializeStep(LOANER_STEP));
      })
      .catch((error) => dispatch(initializeErrorStep(error, true)));
  dispatch(setLastRequest(request));
  request();
};
export const selectShuttle = () => (dispatch, getState) => {
  dispatch({
    type: SET_TRANSPORT,
    payload: {
      transport: SHUTTLE_TRANSPORTATION_TYPE,
    },
  });
  const request = () =>
    addTransport(
      appCheckinIdSelector(getState()),
      chatAppointmentIdSelector(getState()),
      SHUTTLE_TRANSPORTATION_TYPE,
    )
      .then(() => {
        dispatch(initializeStep(SHUTTLE_STEP));
      })
      .catch((error) => dispatch(initializeErrorStep(error, true)));
  dispatch(setLastRequest(request));
  request();
};

export const selectUber = () => (dispatch) => {
  dispatch({
    type: SET_TRANSPORT,
    payload: {
      transport: UBER_TRANSPORTATION_TYPE,
    },
  });
  dispatch(initializeStep(UBER_STEP, {}, { showMap: true }));
};

export const requestRide = () => (dispatch) => {
  dispatch({
    type: FETCH_UBER_DETAILS,
    payload: {
      details: {
        vehicle: {
          make: "Audi",
          model: "RS5",
        },
        driver: {
          name: "Ryan",
        },
        eta: "14",
      },
    },
  });
  dispatch(initializeFinalStep());
};

export const finalizeVisit = () => initializeFinalStep();

export const getListOfAvailableQuestionnaires = () => (dispatch, getState) => {
  fetchListOfAvailableQuestionnaires(
    appCheckinIdSelector(getState()),
    chatAppointmentIdSelector(getState()),
  )
    .then((result) => {
      dispatch({
        type: FETCH_QUESTIONNAIRES_SUCCSESS,
        payload: result.find((item) => item.kind === "mobile_technician"),
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_QUESTIONNAIRES_ERROR,
        payload: error,
      });
    });
};

export const getlistOfAvailableQuestionnairesAnswers =
  () => (dispatch, getState) => {
    fetchlistOfAvailableQuestionnairesAnswers(
      appCheckinIdSelector(getState()),
      chatAppointmentIdSelector(getState()),
    )
      .then((result) => {
        dispatch({
          type: FETCH_QUESTIONNAIRES_ANSWERS_SUCCSESS,
          payload: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_QUESTIONNAIRES_ANSWERS_ERROR,
          payload: error,
        });
      });
  };

export const addQuestionnairesAnswers =
  (answers, signature) => (dispatch, getState) => {
    const {
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_zipcode,
    } = chatMobileTechnicianJobSelector(getState());

    createQuestionnairesAnswers(
      appCheckinIdSelector(getState()),
      chatAppointmentIdSelector(getState()),
      {
        questionnaire_id: chatQuestionnairesId(getState()),
        customer_id: chatCustomerIdSelector(getState()),
        vehicle_id: chatVehicleId(getState()),
        response: answers,
        signature: signature,
        address_line1,
        address_line2,
        address_city,
        address_state,
        address_zipcode,
      },
    )
      .then((result) => {
        dispatch({
          type: ADD_QUESTIONNAIRES_ANSWERS_SUCCSESS,
          payload: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_QUESTIONNAIRES_ANSWERS_ERROR,
          payload: error,
        });
      });
  };
