import React, { Fragment } from "react";
import { TransitionGroup } from "react-transition-group";
import { bool, node } from "prop-types";

const AnimationGroup = ({ isComplete, children }) =>
  isComplete ? (
    <Fragment>{children}</Fragment>
  ) : (
    <TransitionGroup
      appear
      component={null}
    >
      {children}
    </TransitionGroup>
  );

AnimationGroup.propTypes = {
  isComplete: bool,
  children: node.isRequired,
};

AnimationGroup.defaultProps = {
  isComplete: false,
};

export default AnimationGroup;
