import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import cx from "classnames";
import * as routes from "shared/constants/routes";
import { cancelAppointment, editBooking } from "store/actions/chat-actions";
import {
  appAppointmentSlotsSelector,
  appDealershipSlugSelector,
  appJobSlotsSelector,
} from "store/selectors/app-selectors";
import { chatIsPickupJobCheckinSelector } from "store/selectors/chat-selectors";
import { ReactComponent as IconCancel } from "assets/icons/cancel.svg";
import { ReactComponent as IconReschedule } from "assets/icons/reschedule.svg";
import { ReactComponent as IconSettings } from "assets/icons/settings.svg";
import styles from "./styles.module.scss";

const ModifyOrReschedule = ({
  editBooking,
  cancelAppointment,
  dealershipSlug,
  appointmentSlots,
  jobSlots,
  isPickupJobCheckin,
}) => {
  const history = useHistory();

  const cancelFunc = () => {
    cancelAppointment();
    history.push(routes.checkinWelcomePath(dealershipSlug));
  };

  return (
    <div className={styles.body}>
      <div className={styles.list}>
        <div
          className={cx(styles.listItem, styles.normal)}
          onClick={() => {
            editBooking();
            history.push(routes.modifyPath(dealershipSlug));
          }}
        >
          <IconSettings />
          <p className={styles.listItemText}>Modify services</p>
        </div>
        {(isPickupJobCheckin && jobSlots.length) ||
        (!isPickupJobCheckin && appointmentSlots.length) ? (
          <Link
            to={routes.reschedulePath(dealershipSlug)}
            className={cx(styles.listItem, styles.normal)}
          >
            <IconReschedule />
            <p className={styles.listItemText}>Reschedule</p>
          </Link>
        ) : null}
        <div
          onClick={() => cancelFunc()}
          className={cx(styles.listItem, styles.danger)}
        >
          <IconCancel />
          <p className={styles.listItemText}>Cancel reservation</p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  dealershipSlug: appDealershipSlugSelector(state),
  appointmentSlots: appAppointmentSlotsSelector(state),
  jobSlots: appJobSlotsSelector(state),
  isPickupJobCheckin: chatIsPickupJobCheckinSelector(state),
});

export default connect(mapStateToProps, {
  editBooking,
  cancelAppointment,
})(ModifyOrReschedule);
