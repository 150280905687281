import React from "react";
import { DELAY_500 } from "shared/constants/delays";
import Animation, { SLIDE_UP } from "components/common/Animation";
import AnimationGroup from "components/common/AnimationGroup";

const animationWrapper =
  (Component, delay = DELAY_500, type = SLIDE_UP) =>
  (props) =>
    (
      <AnimationGroup>
        <Animation
          type={type}
          delay={delay}
        >
          <Component {...props} />
        </Animation>
      </AnimationGroup>
    );

export default animationWrapper;
