import { partition } from "ramda";
import {
  AVAILABLE_RECALLS_STEP,
  BOOKING_SUMMARY_STEP,
  FINAL_STEP,
} from "shared/constants/checkin-steps";
import { isDevelopmentEnv } from "shared/utils";
import { appAppointmentGuidSelector } from "store/selectors/app-selectors";
import {
  chatIsAppointmentFinalizedSelector,
  servicesSelector,
} from "store/selectors/chat-selectors";
import {
  FETCH_REPAIR_ORDER_DETAILS,
  FETCH_UBER_DETAILS,
  RESCHEDULE_FAILURE,
  RESCHEDULE_SUCCESS,
  SET_AVAILABLE_RECALLS,
  SET_PRESELECTED_RECALLS,
  inintializeCustomerPages,
  initializeChat,
  initializeErrorStep,
  initializeFinalStep,
  initializeStep,
  setLoadingState,
} from "./chat-actions";
import {
  FETCH_APPOINTMENT_DETAILS_ERROR,
  FETCH_APPOINTMENT_DETAILS_SUCCESS,
  changeWelcomeScreenType,
} from "./welcome-actions";

export const FETCH_APPOINTMENT_SLOTS = "FETCH_APPOINTMENT_SLOTS";
export const FETCH_JOB_SLOTS = "FETCH_JOB_SLOTS";
export const FETCH_MOBILE_TECHNICIAN_SLOTS = "FETCH_MOBILE_TECHNICIAN_SLOTS";
export const STORE_APPOINTMENT_GUID = "STORE_APPOINTMENT_GUID";
export const EXIT = "EXIT";
export const NOT_SYNCED = "not_synced";
export const BACK_FROM_CHAT = "back_from_chat";

const FETCH_APPOINTMENT_RESPONSE_TYPE = "import_appointment";
const CONVERT_APPOINTMENT_TO_RO_RESPONSE_TYPE = "add_repair_order";
const FETCH_UBER_RESPONSE_TYPE = "update_transport";
const REQUEST_UBER_RESPONSE_TYPE = "add_transport";
const UPDATE_APPOINTMENT_RESPONSE_TYPE = "update_appointment";
const CANCEL_APPOINTMENT_RESPONSE_TYPE = "cancel_appointment";
const IMPORT_RECALLS_RESPONSE_TYPE = "import_recalls";

export const testAppointment = (dispatch, getState) => {
  const data = {
    id: 1975,
    appointment_number: "14470",
    client_waiting: false,
    phone_number: "123123123",
    appointment_datetime: "Tuesday, April 7 2020, 6:00 PM",
    aasm_state: "open",
    appraisal_requested: false,
    qr_code: {
      url: "https://ng-concierge-staging.s3-eu-central-1.amazonaws.com/uploads/appointment/qr_code/1975/appointment_qr_code_197520200330-6-l5yp8s.png?X-Amz-Expires=600&X-Amz-Date=20200330T134404Z&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ5JLNL4PJEGALG3A%2F20200330%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-SignedHeaders=host&X-Amz-Signature=6cb62e232332bdb8c2b4f93ee8bb3d40353764b45fa301343eb6d8888630e7a5",
    },
    menu_items: {
      extension: [
        {
          id: 78,
          name: "Extension service",
          kind: "extension",
          decision_tree: null,
          op_code: "00VWBATSX",
          service_id: 1,
          default: false,
          menu_template_id: 34,
          fee: 1,
          allocated_labor_time: 0.1,
          menu_item_targets: [
            {
              id: 57,
              price: 1,
              allocated_labor_time: 0.1,
              mileage_range: [1, 50000],
              vehicle_sets: [
                {
                  id: 12,
                  make: "Volkswagen",
                  model: "Passat",
                  model_year: 2014,
                },
              ],
            },
          ],
          package_items: [],
        },
      ],
      concern: [
        {
          id: 101,
          name: "Fluid leak",
          kind: "concern",
          decision_tree: {
            id: 53,
            intro: {
              text: "hello, I would like to ask you some questions",
              goto: 0,
            },
            steps: [
              {
                type: "question",
                text: "What's the color?",
                answers: [
                  { text: "red", goto: "END" },
                  { text: "green", goto: "END" },
                  { text: "blue", goto: "END" },
                ],
              },
            ],
          },
          op_code: "99VWZRBRFL",
          service_id: 45,
          default: true,
          menu_template_id: 1,
          fee: 69.95,
          allocated_labor_time: 0.1,
          menu_item_targets: [
            {
              id: 80,
              price: 69.95,
              allocated_labor_time: 0.1,
              mileage_range: [0, 0],
              vehicle_sets: [],
            },
          ],
          package_items: [],
        },
      ],
    },
    vehicle_set: {
      id: 12,
      make: "Volkswagen",
      model: "Passat",
      model_year: 2014,
    },
    customer: {
      id: 60,
      fetched_details: {
        company_number: "Z11",
        customer_number: "1027657",
        ccid: null,
        entity_type: "I",
        last_name: "AGA",
        first_name: "TEST",
        middle_name: null,
        salutation: null,
        address1: null,
        city: null,
        state: null,
        zip_code: "0",
        county: null,
        home_phone: "0",
        cell_phone: "123123123",
        business_phone: "0",
        business_phone_ext: "0",
        fax_phone: "0",
        email1: "aga@a.pl",
        email2: null,
        vehicles: {
          vin: [
            "TESTVINVWPASSAT14",
            "TESTVINVWGOLF2016",
            "4J4V04X39SNFPG6XE",
            "6SGR368R1QZKUGCB7",
          ],
        },
        cccd: "210000000004181768",
      },
      first_name: "Test",
      last_name: "Aga",
      email: "aga@a.pl",
      phone_number: "123123123",
      customer_number: "1027657",
      state: "created",
    },
    vehicle: {
      id: 16,
      fetched_details: {
        company_number: "Z11",
        vin: "TESTVINVWPASSAT14",
        stock_number: null,
        type: "U",
        stock_status: "C",
        make: "VOLKSWAGEN",
        model: "PASSAT",
        model_year: "2014",
        stock_date: "0",
        customers: { customer_number: "1027657" },
        last_service_date: "0001-01-01T00:00:00.000+00:00",
        license_number: "ABC-456",
        created_date_time: "2018-12-21T07:44:27.461+00:00",
        updated_date_time: "2018-12-21T07:44:27.461+00:00",
      },
      plate_number: "ABC-456",
      region: null,
      vin: "TESTVINVWPASSAT14",
      state: "created",
      image: {
        url: "https://ng-concierge-staging.s3-eu-central-1.amazonaws.com/car_images/volkswagen/passat.png?X-Amz-Expires=600&X-Amz-Date=20200330T134404Z&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ5JLNL4PJEGALG3A%2F20200330%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-SignedHeaders=host&X-Amz-Signature=161767211cf1816c16d452d60591df00cb2c655783f7c83d3f8ac4fd4420ccf3",
      },
      customer_number: "1027657",
      mileage: 35002,
    },
    transport: null,
    repair_order: null,
    service_advisor: {
      id: 18,
      writer_id: "999",
      active: true,
      name: "DTDMS SUPPORT",
      photo: {
        url: "https://ng-concierge-staging.s3-eu-central-1.amazonaws.com/uploads/service_advisor/photo/18/audi_q7.png?X-Amz-Expires=600&X-Amz-Date=20200330T134404Z&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ5JLNL4PJEGALG3A%2F20200330%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-SignedHeaders=host&X-Amz-Signature=013193636313931e9b6cda8984f505c8c22b463f9d65d021a7cadd8c87b2a9fb",
      },
      working_hours: {
        mon: {
          to: "23:00",
          from: "05:00",
          order: 1,
        },
        tue: {
          to: "23:00",
          from: "05:00",
          order: 2,
        },
        wed: {
          to: "16:00",
          from: "06:00",
          order: 3,
        },
        thu: {
          to: "16:00",
          from: "07:00",
          order: 4,
        },
        fri: {
          to: "16:00",
          from: "07:00",
          order: 5,
        },
        sat: {
          to: "16:00",
          from: "07:00",
          order: 6,
        },
        sun: {
          order: 7,
          to: "23:00",
          from: "01:00",
        },
      },
      employee_number: 999,
    },
    bookings_menu_items: [
      {
        id: 2679,
        name: "Extension service",
        kind: "extension",
        price: 1,
        allocated_labor_time: 0.1,
        priceable_id: 1975,
        priceable_type: "Appointment",
        menu_item_id: 78,
      },
      {
        id: 2678,
        name: "Fluid leak",
        kind: "concern",
        price: 69.95,
        allocated_labor_time: 0.1,
        priceable_id: 1975,
        priceable_type: "Appointment",
        menu_item_id: 101,
      },
    ],
    decision_tree_results: [
      {
        id: 244,
        additional_note: "asda",
        details: [
          {
            text: "hello, I would like to ask you some questions",
            source: "SOURCE_CONCIERGE",
          },
          {
            text: "What's the color?",
            source: "SOURCE_CONCIERGE",
          },
          { text: "blue", source: "SOURCE_USER" },
        ],
        menu_item_id: 101,
        appointment_id: 1975,
      },
    ],
    line_items: [
      {
        id: 2092,
        name: "Extension service",
        fee: "1.0",
        labor_hours: "1.0",
        operation_code: "00VWBATSX",
      },
      {
        id: 2091,
        name: "Fluid leak",
        fee: "69.95",
        labor_hours: "1.0",
        operation_code: "99VWZRBRFL",
      },
    ],
    jobs: [
      {
        id: 173,
        collection_time: "20200813T130000",
        location: "1540 Gaylord Trail, Grapevine, TX 76051, USA",
        job_type: "pickup",
        notes: "",
        aasm_state: "driver_assigned",
        activity: {
          driver_assigned_at: "20200813T113228",
        },
        main_driver: {
          id: 4,
          name: "Roelof Bekkenenks",
          photo: {
            url: "https://ng-concierge-staging.s3-eu-central-1.amazonaws.com/uploads/driver/photo/4/adviser_3x.png?X-Amz-Expires=600&X-Amz-Date=20200813T115144Z&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ5JLNL4PJEGALG3A%2F20200813%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-SignedHeaders=host&X-Amz-Signature=5ce89fdc120e18a2beac513ebda3cc2fd06afde7b1167cc6d2f25e83e2e4ce9c",
          },
          phone_number: "123123888",
          working_hours: {
            mon: {
              to: "16:00",
              from: "08:00",
              order: 1,
            },
            tue: {
              to: "16:00",
              from: "08:00",
              order: 2,
            },
            wed: {
              to: "16:00",
              from: "08:00",
              order: 3,
            },
            thu: {
              to: "16:00",
              from: "08:00",
              order: 4,
            },
            fri: {
              to: "16:00",
              from: "08:00",
              order: 5,
            },
            sat: {
              to: "16:00",
              from: "08:00",
              order: 6,
            },
            sun: { order: 7 },
          },
          active: true,
          dealership_id: 1,
          created_at: "2020-05-11T06:05:06.033Z",
          updated_at: "2020-06-15T09:48:24.340Z",
        },
        co_driver: {
          id: 1,
          name: "Joe Doe",
          photo: {
            url: "https://ng-concierge-staging.s3-eu-central-1.amazonaws.com/uploads/driver/photo/1/adviser_3x.png?X-Amz-Expires=600&X-Amz-Date=20200813T115144Z&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ5JLNL4PJEGALG3A%2F20200813%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-SignedHeaders=host&X-Amz-Signature=5e80a6dd389da0298703c2fc8aad2bfa140484914d837d9583e590af326f79d7",
          },
          phone_number: "123123129",
          working_hours: {
            mon: {
              to: "18:00",
              from: "08:00",
              order: 1,
            },
            tue: {
              to: "16:00",
              from: "08:00",
              order: 2,
            },
            wed: {
              to: "11:00",
              from: "09:00",
              order: 3,
            },
            thu: {
              to: "16:00",
              from: "08:00",
              order: 4,
            },
            fri: {
              to: "16:00",
              from: "08:00",
              order: 5,
            },
            sat: {
              to: "18:00",
              from: "06:00",
              order: 6,
            },
            sun: { order: 7 },
          },
          active: true,
          dealership_id: 1,
          created_at: "2020-05-05T16:04:26.153Z",
          updated_at: "2020-06-15T09:49:01.261Z",
        },
      },
    ],
  };
  dispatch({
    type: FETCH_APPOINTMENT_DETAILS_SUCCESS,
    payload: data,
  });
  dispatch(inintializeCustomerPages(new Date()));
  dispatch(initializeChat());
};

export const actionCableListener = (message) => (dispatch, getState) => {
  if (isDevelopmentEnv()) {
    console.log("Action cable response: ", message); // eslint-disable-line no-console
  }

  if (message.id) {
    dispatch({
      type: FETCH_APPOINTMENT_DETAILS_SUCCESS,
      payload: message,
    });
  }

  const { action, data, errors } = message;

  switch (action) {
    case FETCH_APPOINTMENT_RESPONSE_TYPE:
      const guid = appAppointmentGuidSelector(getState());

      if (data) {
        dispatch({
          type: FETCH_APPOINTMENT_DETAILS_SUCCESS,
          payload: data,
        });
        dispatch(inintializeCustomerPages(new Date(data.appointment_datetime)));
        dispatch(initializeChat());
      } else {
        dispatch({
          type: FETCH_APPOINTMENT_DETAILS_ERROR,
          payload: {
            sourceGuid: !!guid,
            errors,
          },
        });
        dispatch(changeWelcomeScreenType("error"));
        if (guid) {
          dispatch({
            type: STORE_APPOINTMENT_GUID,
            payload: null,
          });
        }
      }
      break;
    case IMPORT_RECALLS_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      const { bookingsMenuItems } = servicesSelector(getState());
      if (data.length > 0) {
        const [preselectedRecalls, uniqueRecalls] = partition(
          (recall) =>
            bookingsMenuItems.find((bmi) => bmi.recall_id === recall.id),
          data,
        );
        dispatch({
          type: SET_PRESELECTED_RECALLS,
          payload: { preselectedRecalls },
        });

        if (uniqueRecalls.length > 0) {
          dispatch({
            type: SET_AVAILABLE_RECALLS,
            payload: { recalls: uniqueRecalls },
          });
          dispatch(initializeStep(AVAILABLE_RECALLS_STEP));
        } else {
          dispatch(initializeStep(BOOKING_SUMMARY_STEP));
        }
      } else {
        dispatch(initializeStep(BOOKING_SUMMARY_STEP));
      }
      break;
    case CONVERT_APPOINTMENT_TO_RO_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      if (data) {
        dispatch({
          type: FETCH_REPAIR_ORDER_DETAILS,
          payload: data,
        });
        dispatch(initializeStep(FINAL_STEP));
      } else {
        dispatch(initializeErrorStep(errors, true));
      }
      break;
    case REQUEST_UBER_RESPONSE_TYPE:
      if (errors) {
        dispatch(setLoadingState(false));
        dispatch(initializeErrorStep(errors, true));
      }
      break;
    case FETCH_UBER_RESPONSE_TYPE:
      if (data) {
        dispatch({
          type: FETCH_UBER_DETAILS,
          payload: data,
        });
        dispatch(initializeFinalStep());
      } else {
        dispatch(initializeErrorStep(errors, true));
      }
      break;

    case UPDATE_APPOINTMENT_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      if (data) {
        dispatch({
          type: FETCH_APPOINTMENT_DETAILS_SUCCESS,
          payload: data,
        });
        if (chatIsAppointmentFinalizedSelector(getState())) {
          dispatch(initializeStep(FINAL_STEP));
        }
        dispatch({ type: RESCHEDULE_SUCCESS });
      } else {
        if (chatIsAppointmentFinalizedSelector(getState())) {
          dispatch(initializeErrorStep(errors, true));
        } else {
          dispatch({
            type: RESCHEDULE_FAILURE,
            payload: errors,
          });
        }
      }
      break;
    case CANCEL_APPOINTMENT_RESPONSE_TYPE:
      break;

    default:
      break;
  }
};

export const exit = () => (dispatch) => {
  dispatch({ type: EXIT, payload: {} });
};

export const backFromChat = () => (dispatch) => {
  dispatch({ type: BACK_FROM_CHAT, payload: {} });
};

export const storeAppointmentGuid = (guid) => (dispatch) => {
  dispatch(changeWelcomeScreenType("edit"));
  dispatch({
    type: STORE_APPOINTMENT_GUID,
    payload: guid,
  });
};
