import React from "react";
import cx from "classnames";
import logo_white from "assets/images/carmen_logo_white.svg";
import logo from "assets/images/violet.png";
import styles from "./styles.module.scss";

const Footer = ({ isDark, isFixed }) => {
  return (
    <div
      className={cx(styles.footer, {
        [styles.dark]: isDark,
        [styles.fixed]: isFixed,
      })}
    >
      <img
        src={isDark ? logo_white : logo}
        alt=""
        className={styles.logo}
      />
    </div>
  );
};

export default Footer;
