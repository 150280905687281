import React, { Fragment } from "react";
import { string } from "prop-types";
import { DELAY_500 } from "shared/constants/delays";
import AnimatedTextMessage from "components/common/AnimatedTextMessage";
import AnimationGroup from "components/common/AnimationGroup";
import { SOURCE_CONCIERGE, SOURCE_USER } from "components/common/TextMessage";

const DecisionTreeDescriptionStep = ({ serviceName, description }) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_500}
        message={`Regarding your ${
          serviceName || "concern"
        }. Is there anything you want to add to your original statement?`}
        source={SOURCE_CONCIERGE}
        isClosing
      />
    </AnimationGroup>
    {description && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={description}
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

DecisionTreeDescriptionStep.propTypes = {
  description: string,
  serviceName: string,
};

DecisionTreeDescriptionStep.defaultProps = {
  description: "",
  serviceName: "",
};

export default DecisionTreeDescriptionStep;
