import { sendDecisionTreeResult } from "shared/api";
import {
  DECISION_TREE_DESCRIPTION_STEP, // ADDITIONAL_NOTES_STEP,
  DECISION_TREE_STEP,
} from "shared/constants/checkin-steps";
import { DELAY_500 } from "shared/constants/delays";
import {
  goToAdditionalServicesStep,
  initializeErrorStep,
  initializeStep,
  setLastRequest,
  updateCurrentStep,
} from "store/actions/chat-actions";
import { appCheckinIdSelector } from "store/selectors/app-selectors";
import {
  chatAppointmentIdSelector,
  chatDecisionTreeResultsSelector,
  chatDecisionTreesSelector,
  chatOrderSelector,
  servicesSelector,
} from "store/selectors/chat-selectors";
import { SOURCE_CONCIERGE, SOURCE_USER } from "components/common/TextMessage";

export const INITIALIZE_DECISION_TREE = "INITIALIZE_DECISION_TREE";
export const UPDATE_DECISION_TREE = "UPDATE_DECISION_TREE";
export const SET_DECISION_TREE_DESCRIPTION = "SET_DECISION_TREE_DESCRIPTION";

const updateDecisionTree = (text, source) => ({
  type: UPDATE_DECISION_TREE,
  payload: { text, source },
});

const moveToNextDecisionTree =
  (serviceId, stepId, answer) => (dispatch, getState) => {
    const decisionTree = chatDecisionTreesSelector(getState()).find(
      (t) => t.serviceId === serviceId,
    );
    const nextStep = decisionTree.steps[stepId];
    const delay = answer ? DELAY_500 : 0;
    dispatch(updateDecisionTree(nextStep.text, SOURCE_CONCIERGE));
    setTimeout(() => {
      if (nextStep.type === "question") {
        dispatch(
          initializeStep(
            DECISION_TREE_STEP,
            {
              question: nextStep.text,
            },
            {
              serviceId,
              answers: nextStep.answers,
            },
          ),
        );
      } else {
        dispatch(
          initializeStep(DECISION_TREE_STEP, {
            question: nextStep.text,
          }),
        );
        // eslint-disable-next-line no-use-before-define
        setTimeout(
          () => dispatch(nextDecisionTreeStep(serviceId, nextStep.goto)),
          DELAY_500,
        );
      }
    }, delay);
  };

export const nextDecisionTreeStep =
  (serviceId, stepId, answer, isFilled) => (dispatch, getState) => {
    if (answer) {
      dispatch(updateDecisionTree(answer, SOURCE_USER));
      dispatch(updateCurrentStep({ answer }, { isComplete: true }));
    }

    const service = servicesSelector(getState()).bookingsMenuItems.find(
      (service) => service.menu_item_id === serviceId,
    );
    if (stepId === "END" || isFilled) {
      dispatch(
        initializeStep(
          DECISION_TREE_DESCRIPTION_STEP,
          {
            serviceName: service && service.name,
          },
          {
            description: service && service.additional_note,
            isComplete: false,
          },
        ),
      );
    } else {
      try {
        dispatch(moveToNextDecisionTree(serviceId, stepId, answer));
      } catch (err) {
        dispatch(
          initializeStep(
            DECISION_TREE_DESCRIPTION_STEP,
            { serviceName: service.name },
            {
              description: service && service.additional_note,
              isComplete: false,
            },
          ),
        );
      }
    }
  };

export const initializeDecisionTree =
  (decisionTree, isFilled) => (dispatch) => {
    dispatch({
      type: INITIALIZE_DECISION_TREE,
      payload: {
        serviceId: decisionTree.serviceId,
        intro: decisionTree.intro.text,
      },
    });
    if (!isFilled) {
      dispatch(
        initializeStep(DECISION_TREE_STEP, {
          question: decisionTree.intro.text,
          isFilled,
        }),
      );
    }
    setTimeout(
      () =>
        dispatch(
          nextDecisionTreeStep(
            decisionTree.serviceId,
            decisionTree.intro.goto,
            null,
            isFilled,
          ),
        ),
      DELAY_500,
    );
  };

export const finishDecisionTree = () => (dispatch, getState) => {
  const decisionTrees = chatDecisionTreesSelector(getState());
  const order = chatOrderSelector(getState());
  const decisionTreeResults = chatDecisionTreeResultsSelector(getState());
  const previousDecisionTreeResult =
    decisionTreeResults[decisionTreeResults.length - 1];
  const isFilledByAdvisor = (order.decisionTreeResults || []).find(
    (tree) => tree.menu_item_id === previousDecisionTreeResult.serviceId,
  );

  const handleNextStep = () => {
    if (decisionTreeResults.length === decisionTrees.length) {
      dispatch(goToAdditionalServicesStep());
    } else {
      const nextDecisionTree = decisionTrees[decisionTreeResults.length];
      const isFilled = (order.decisionTreeResults || []).find(
        (tree) => tree.menu_item_id === nextDecisionTree.serviceId,
      );

      dispatch(initializeDecisionTree(nextDecisionTree, !!isFilled));
    }
  };

  const request = () =>
    sendDecisionTreeResult(
      appCheckinIdSelector(getState()),
      chatAppointmentIdSelector(getState()),
      previousDecisionTreeResult,
    )
      .then(() => handleNextStep())
      .catch((error) => dispatch(initializeErrorStep(error)));

  if (isFilledByAdvisor) {
    handleNextStep();
  } else {
    dispatch(setLastRequest(request));
    request();
  }
};

export const describeDecisionTree = (description) => (dispatch) => {
  dispatch(
    updateCurrentStep({ description }, { description: "", isComplete: true }),
  );
  dispatch({
    type: SET_DECISION_TREE_DESCRIPTION,
    payload: { description },
  });
  dispatch(finishDecisionTree());
};
