import React from "react";
import { connect } from "react-redux";
import { appDealershipPublicIdSelector } from "store/selectors/app-selectors";
import styles from "./styles.module.scss";

const DealershipInfo = ({ dealershipPublicId }) => {
  return (
    <div className={styles.body}>
      <div className={styles.card}>
        <div className={styles.cardBlock}>
          <div className={styles.textLink}>
            <a
              href={`https://reserve.getcarmen.com/carmenservice?dealershipId=${dealershipPublicId}`}
            >
              Download the app
            </a>
          </div>
          <div className={styles.appointment}>
            <p className={styles.appointmentText}>Dealer number</p>
            <p className={styles.appointmentValue}>{dealershipPublicId}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  dealershipPublicId: appDealershipPublicIdSelector(state),
});

export default connect(mapStateToProps)(DealershipInfo);
