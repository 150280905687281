import { combineReducers } from "redux";
import app from "./app-reducer";
import chat from "./chat-reducer";

const reducers = combineReducers({
  app,
  chat,
});

export default reducers;
