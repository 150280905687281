import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import { isBefore } from "date-fns";
import mainNav from "shared/constants/main-nav";
import { checkinChatPath } from "shared/constants/routes";
import { isMobile } from "shared/utils";
import { appDealershipSlugSelector } from "store/selectors/app-selectors";
import {
  chatIsAppointmentFinalizedSelector,
  chatMobileTechnicianJobSelector,
  chatMobileTechnicianTimeVarianceSelector,
  chatPickupJobSelector,
  chatPickUpTimeVarianceSelector,
  chatQuestionnairesAnswers,
  chatReschedulingErrorSelector,
  customerPageSelector,
} from "store/selectors/chat-selectors.js";
import Button from "components/common/Button/Button";
import check from "assets/icons/check.svg";
import messageimg from "assets/icons/message.svg";
import phoneimg from "assets/icons/phone.svg";
import carimg from "assets/images/car.png";
import userimg from "assets/images/user.png";
import Questionnaires from "./Questionnaires";
import styles from "./styles.module.scss";

const Main = ({
  isCheckInAvailable,
  vehicle,
  vehicleImage,
  date,
  dealershipPhone,
  dealershipEmail,
  advisor,
  calendarLink,
  dealershipSlug,
  isAppointmentFinalized,
  isPickupJobCheckin,
  jobDate,
  mobileTechnicianJob,
  mobileTechnicianTimeVariance,
  questionnairesAnswers,
  pickupJob,
  pickupTimeVariance,
  status,
  reschedulingError,
}) => {
  const [showQuestions, setShowQuestions] = useState(false);
  const [isPastAppointment, setIsPastAppointment] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (date) {
      setIsPastAppointment(isBefore(new Date(date), new Date()))
    }
  }, [date]);

  const handleCalendarOpen = () => {
    if (
      !isMobile() &&
      (calendarLink.startsWith("data") || calendarLink.startsWith("BEGIN"))
    ) {
      const filename = "download.ics";
      const blob = new Blob([calendarLink], {
        type: "text/calendar;charset=utf-8",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(calendarLink, "_blank");
    }
  };

  const formatedDate = (value) => {
    return moment(value).format("Do MMMM YYYY [at] h:mma");
  };

  return (
    <div className={styles.body}>
      {reschedulingError && (
        <div className={styles.alert}>
          <p>There are no free appointment slots for the selected time</p>
        </div>
      )}
      <div
        className={cx(styles.reserv, styles.box, {
          [styles.reserv__checkin]: isCheckInAvailable,
        })}
      >
        <div className={cx(styles.reserv__title, styles.box__header)}>
          {isPickupJobCheckin ? "Pickup" : "Service"} reservation for {vehicle}
        </div>
        <div className={cx(styles.box__body, styles.reserv__body)}>
          <div className={styles.reserv__content}>
            <img
              className={styles.reserv__carimg}
              src={vehicleImage || carimg}
              alt="carimg"
            />
            <div>
              <div className={styles.reserv__info}>
                {isPickupJobCheckin
                  ? formatedDate(jobDate)
                  : formatedDate(date)}
              </div>
              {mobileTechnicianJob &&
                mobileTechnicianTimeVariance !== null &&
                mobileTechnicianTimeVariance !== 0 && (
                  <div className={styles.reserv__note}>
                    {`Service time may vary by ~ ${mobileTechnicianTimeVariance} mins`}
                  </div>
                )}
              {pickupJob &&
                pickupTimeVariance !== null &&
                pickupTimeVariance !== 0 && (
                <div className={styles.reserv__note}>
                  {`Time may vary by ~ ${pickupTimeVariance} mins`}
                </div>
              )}
            </div>
          </div>
          {isCheckInAvailable && !isPastAppointment && status !== 'checked_in' ? (
            <Button
              color={"#ffffff"}
              bgColor={isAppointmentFinalized ? "#37B05F" : "#0BCAF9"}
              disabledBgColor={isAppointmentFinalized ? "" : "#0bc9f940"}
              text={isAppointmentFinalized ? "Checked In" : "Online check in"}
              onClick={
                !isAppointmentFinalized
                  ? () => history.push(checkinChatPath(dealershipSlug))
                  : null
              }
              disabled={
                mobileTechnicianJob
                  ? Object.keys(questionnairesAnswers).length === 0
                  : false
              }
            />
          ) : null}
          {mobileTechnicianJob && !isPastAppointment &&
            Object.keys(questionnairesAnswers).length === 0 && (
              <Button
                color={"#ffffff"}
                bgColor={"#0BCAF9"}
                disabledBgColor={"#0bc9f940"}
                text="Mobile service questionnaire"
                onClick={() => setShowQuestions(!showQuestions)}
              />
            )}
          {mobileTechnicianJob &&
            Object.keys(questionnairesAnswers).length > 0 && (
              <div className={styles.questionnairesAnswersStatus}>
                <div className={styles.successIcon}>
                  <img
                    src={check}
                    alt=""
                  />
                </div>
                <div className={styles.title}>
                  Mobile service questionnaire completed
                </div>
              </div>
            )}
        </div>
      </div>
      {mobileTechnicianJob && (
        <div className={cx(styles.employee, styles.box)}>
          <>
            <img
              className={styles.employee__img}
              alt=""
              src={mobileTechnicianJob.technician.photo.url || userimg}
            />
            <div className={styles.employee__content}>
              <div className={styles.employee__title}>Mobile Technician</div>
              <div className={styles.employee__name}>
                {mobileTechnicianJob.technician.name}
              </div>
              <div className={styles.employee__title}>Service address</div>
              <div className={styles.employee__name}>
                {mobileTechnicianJob.address}
              </div>
            </div>
          </>
        </div>
      )}
      <div className={cx(styles.employee, styles.box)}>
        {advisor && advisor.name && (
          <>
            <img
              className={styles.employee__img}
              alt=""
              src={advisor.photo || userimg}
            />
            <div className={styles.employee__content}>
              <div className={styles.employee__title}>Advisor</div>
              <div className={styles.employee__name}>{advisor.name}</div>
            </div>
          </>
        )}
          <div className={styles.employee__btns}>
            {dealershipEmail &&(
              <a
              href={`mailto:${dealershipEmail}`}
                className={cx(styles.roundBtn, styles.roundBtn_mint)}
              >
                <img
                  src={messageimg}
                  alt=""
                />
              </a>
            )}
            {dealershipPhone && (
              <a
                href={`tel:${dealershipPhone}`}
                className={cx(styles.roundBtn, styles.roundBtn_purple)}
              >
                <img
                  src={phoneimg}
                  alt="" />
              </a>
            )}
          </div>
      </div>
      <div className={cx(styles.nav, styles.box)}>
        {mainNav(dealershipSlug)[
          isAppointmentFinalized || isPastAppointment ? "checkedIn" : "default"
        ].map((link) =>
          link.id === "calendar" ? (
            <div
              className={styles.nav__item}
              key={link.id}
              onClick={() => handleCalendarOpen()}
            >
              <img
                className={styles.nav__icon}
                src={link.icon}
                alt=""
              />
              <div className={styles.nav__text}>{link.text}</div>
            </div>
          ) : (
            <div
              className={styles.nav__item}
              key={link.id}
              onClick={() => history.push(link.route)}
            >
              <img
                className={styles.nav__icon}
                src={link.icon}
                alt=""
              />
              <div className={styles.nav__text}>{link.text}</div>
            </div>
          ),
        )}
      </div>
      <Questionnaires
        showQuestions={showQuestions}
        setShowQuestions={setShowQuestions}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...customerPageSelector(state),
  dealershipSlug: appDealershipSlugSelector(state),
  isAppointmentFinalized: chatIsAppointmentFinalizedSelector(state),
  mobileTechnicianJob: chatMobileTechnicianJobSelector(state),
  pickupJob: chatPickupJobSelector(state),
  mobileTechnicianTimeVariance: chatMobileTechnicianTimeVarianceSelector(state),
  pickupTimeVariance: chatPickUpTimeVarianceSelector(state),
  questionnairesAnswers: chatQuestionnairesAnswers(state),
  reschedulingError: chatReschedulingErrorSelector(state),
});

export default connect(mapStateToProps)(Main);
