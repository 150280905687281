import {
  EXIT,
  FETCH_APPOINTMENT_SLOTS,
  FETCH_JOB_SLOTS,
  FETCH_MOBILE_TECHNICIAN_SLOTS,
  STORE_APPOINTMENT_GUID,
} from "store/actions/app-actions";
import {
  FETCH_APPOINTMENT_ERROR,
  SET_WELCOME_PAGE,
  START_CHECKIN_SUCCESS,
  STORE_DEALERSHIP_SLUG,
} from "store/actions/chat-actions";
import {
  CHANGE_WELCOME_SCREEN_TYPE,
  FETCH_APPOINTMENT_DETAILS_ERROR,
} from "store/actions/welcome-actions";

const initialState = {
  checkinId: null,
  actionCableChannelKey: null,
  dealership: {
    id: 1,
    name: null,
    address: null,
    longitude: null,
    latitude: null,
    welcome_screen: {
      url: null,
    },
    dmsType: "dealer_track",
    hours: null,
    notes: null,
    phone: null,
    email: '',
    services_price_visible: null,
    publicId: null,
  },
  isNoAppointmentByGuid: false,
  isNoAppointmentByPhoneNumber: false,
  isNoCustomerByPhoneNumber: false,
  appointmentGuid: null,
  appointmentSlots: [],
  jobSlots: [],
  welcomeScreenType: "checkin",
  dealershipSlug: null,
};

const app = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STORE_DEALERSHIP_SLUG: {
      return {
        ...state,
        dealershipSlug: payload,
      };
    }
    case START_CHECKIN_SUCCESS:
      const {
        id,
        dealership: {
          address,
          name,
          latitude,
          longitude,
          welcome_screen,
          dms_type,
          working_hours,
          time_zone,
          phone,
          services_price_visible,
          public_id,
          remote_enabled,
          mobile_technician_enabled,
          logo: { url },
          email,
        },
      } = payload;

      return {
        ...state,
        checkinId: id,
        dealership: {
          ...state.dealership,
          id: payload.dealership.id,
          address,
          name,
          latitude,
          longitude,
          welcomeScreen: welcome_screen,
          dmsType: dms_type,
          workingHours: working_hours,
          timeZone: time_zone,
          phone,
          logo: url,
          servicesPriceVisible: services_price_visible,
          publicId: public_id,
          remoteEnabled: remote_enabled,
          mobileTechnicianEnabled: mobile_technician_enabled,
          email,
        },
        actionCableChannelKey: payload.channel_key,
      };
    case STORE_APPOINTMENT_GUID: {
      return {
        ...state,
        appointmentGuid: payload,
      };
    }
    case CHANGE_WELCOME_SCREEN_TYPE: {
      return {
        ...state,
        welcomeScreenType: payload,
        isNoAppointmentByPhoneNumber:
          payload === "checkin" ? false : state.isNoAppointmentByPhoneNumber,
      };
    }
    case FETCH_APPOINTMENT_SLOTS: {
      return {
        ...state,
        appointmentSlots: payload,
      };
    }
    case FETCH_JOB_SLOTS: {
      return {
        ...state,
        jobSlots: payload,
      };
    }
    case FETCH_MOBILE_TECHNICIAN_SLOTS: {
      return {
        ...state,
        mobileTechnicianSlots: payload,
      };
    }
    case FETCH_APPOINTMENT_DETAILS_ERROR: {
      return {
        ...state,
        isNoAppointmentByGuid: payload.sourceGuid,
        isNoAppointmentByPhoneNumber: !payload.sourceGuid,
      };
    }
    case FETCH_APPOINTMENT_ERROR: {
      return {
        ...state,
        isNoCustomerByPhoneNumber: true,
      };
    }
    case SET_WELCOME_PAGE: {
      return {
        ...state,
        isNoCustomerByPhoneNumber: false,
        isNoAppointmentByPhoneNumber: false,
      };
    }
    case EXIT:
      const {
        checkinId,
        actionCableChannelKey,
        appointmentGuid,
        dealershipSlug,
        dealership,
      } = state;

      return {
        ...initialState,
        checkinId,
        actionCableChannelKey,
        appointmentGuid,
        dealershipSlug,
        dealership,
      };
    default:
      return state;
  }
};

export default app;
