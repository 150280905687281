import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import cx from "classnames";
import { bool, func, string } from "prop-types";
import {
  checkinChatPath,
  checkinWelcomePath,
  customerPath,
} from "shared/constants/routes";
import { extractPhoneNumberFromString } from "shared/utils";
import {
  numbersOnly,
  phoneNumberFormatValidator,
  phoneNumberLengthValidator,
} from "shared/validators";
import { phoneNumberAuth } from "store/actions/chat-actions";
import {
  appDealershipDmsTypeSelector,
  appDealershipSlugSelector,
  appIsNoAppointmentByPhoneNumberSelector,
  appIsNoCustomerByPhoneNumberSelector,
} from "store/selectors/app-selectors";
import {
  chatIsCheckInAvailableSelector,
  chatIsInitializedSelector,
} from "store/selectors/chat-selectors";
import Button from "components/common/Button";
import spinnerIcon from "assets/images/spinner.svg";
import styles from "./styles.module.scss";

class PhoneNumberForm extends Component {
  state = {
    phoneNumber: this.props.phoneNumber || "",
    isLoading: false,
    isValid: true,
  };

  confirm = () => {
    if (this.validate()) {
      const phoneNumber = extractPhoneNumberFromString(this.state.phoneNumber);
      this.setState({ isLoading: true });
      this.props.onConfirm(phoneNumber);
    }
  };

  validate = () => {
    const phoneNumberFormatValid = phoneNumberFormatValidator(
      this.state.phoneNumber,
    );
    const phoneNumberLengthValid =
      phoneNumberFormatValid &&
      phoneNumberLengthValidator(
        extractPhoneNumberFromString(this.state.phoneNumber),
        this.props.dmsType,
      );
    const isValid = phoneNumberFormatValid && phoneNumberLengthValid;
    this.setState({ isValid });
    return isValid;
  };

  handleChange = ({ target: { value } }) => {
    if (numbersOnly(value) || value === "") {
      this.setState({ phoneNumber: value });
    }
  };

  render() {
    const { isValid, phoneNumber, isLoading } = this.state;
    const {
      isChatInitialized,
      isNoAppointmentByPhoneNumber,
      isNoCustomerByPhoneNumber,
      isCheckinAvailable,
      dealershipSlug,
    } = this.props;
    if (isChatInitialized) {
      return (
        <Redirect
          to={
            isCheckinAvailable
              ? checkinChatPath(dealershipSlug)
              : customerPath(dealershipSlug)
          }
        />
      );
    }

    if (isNoAppointmentByPhoneNumber || isNoCustomerByPhoneNumber) {
      return <Redirect to={checkinWelcomePath(dealershipSlug)} />;
    }

    return (
      <div className={styles.page}>
        <div className={styles.background}>
          <div className={styles.circle}>
            <div className={styles.circle}>
              <div className={styles.circle}>
                <div className={styles.circle}></div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.container}>
          <div className={cx(styles.modal, styles.box)}>
            <div className={cx(styles.header, styles.box__header)}>
              Check in with phone number
            </div>
            <div className={cx(styles.content, styles.box__body)}>
              <div className={styles.inputContainer}>
                <div className={styles.label}>Phone number</div>
                <input
                  type="tel"
                  className={cx(styles.input, {
                    [styles.invalid]: !isValid,
                  })}
                  value={phoneNumber}
                  disabled={isLoading}
                  onChange={this.handleChange}
                />
                <div className={styles.errors}>
                  {!isValid ? "This phone number is invalid" : ""}
                </div>
              </div>
              <div className={styles.footer}>
                <Button
                  caption={"Confirm"}
                  disabled={isLoading}
                  onClick={this.confirm}
                  isWide
                />
              </div>
            </div>
          </div>
          {isLoading && (
            <div>
              <img
                alt="loading"
                className={styles.loadingState}
                src={spinnerIcon}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

PhoneNumberForm.propTypes = {
  onConfirm: func.isRequired,
  phoneNumber: string,
  isChatInitialized: bool,
  dmsType: string.isRequired,
};

PhoneNumberForm.defaultProps = {
  phoneNumber: "",
  isChatInitialized: false,
};

const mapStateToProps = (state) => ({
  dealershipSlug: appDealershipSlugSelector(state),
  isNoAppointmentByPhoneNumber: appIsNoAppointmentByPhoneNumberSelector(state),
  isNoCustomerByPhoneNumber: appIsNoCustomerByPhoneNumberSelector(state),
  isChatInitialized: chatIsInitializedSelector(state),
  isCheckinAvailable: chatIsCheckInAvailableSelector(state),
  dmsType: appDealershipDmsTypeSelector(state),
});

const actions = {
  onConfirm: phoneNumberAuth,
};

const PhoneNumberFormContainer = connect(
  mapStateToProps,
  actions,
)(PhoneNumberForm);

export default PhoneNumberFormContainer;
