import {
  chooseActionPath,
  dealershipInfoPath,
  detailsPath,
  qrCodePath,
} from "shared/constants/routes";
import calendar from "assets/icons/calendar.png";
import details from "assets/icons/details.svg";
import location from "assets/icons/location.svg";
import modify from "assets/icons/modify.svg";
import qr from "assets/icons/qr.svg";

export default (slug) => ({
  default: [
    {
      id: "calendar",
      icon: calendar,
      text: "Add to Calendar",
      route: null,
    },
    {
      id: "details",
      icon: details,
      text: "View details",
      route: detailsPath(slug),
    },
    {
      id: "modify",
      icon: modify,
      text: "Modify or reschedule",
      route: chooseActionPath(slug),
    },
    {
      id: "dealership",
      icon: location,
      text: "Dealership location",
      route: dealershipInfoPath(slug),
    },
    {
      id: "qrcode",
      icon: qr,
      text: "Download our service app",
      route: qrCodePath(slug),
    },
  ],
  checkedIn: [
    {
      id: "calendar",
      icon: calendar,
      text: "Add to Calendar",
      route: null,
    },
    {
      id: "dealership",
      icon: location,
      text: "Dealership location",
      route: dealershipInfoPath(slug),
    },
    {
      id: "qrcode",
      icon: qr,
      text: "Download our service app",
      route: qrCodePath(slug),
    },
  ],
});
