import React from "react";
import { connect } from "react-redux";
import { bool, func } from "prop-types";
import { exit } from "store/actions/app-actions";
import { replayLastRequest } from "store/actions/chat-actions";
import Button from "components/common/Button";
import animationWrapper from "components/common/animationWrapper";
import styles from "./styles.module.scss";

const ErrorStepInput = ({ canRetry, onRetry, onExit }) =>
  canRetry && (
    <div className={styles.chatInput}>
      <Button
        caption="Exit"
        isSecondary
        onClick={onExit}
        isWide
      />
      <Button
        caption="Try Again"
        onClick={onRetry}
        isWide
      />
    </div>
  );

ErrorStepInput.propTypes = {
  canRetry: bool,
  onExit: func.isRequired,
  onRetry: func.isRequired,
};

ErrorStepInput.defaultProps = {
  canRetry: false,
};

const actions = {
  onExit: exit,
  onRetry: replayLastRequest,
};

const ErrorStepInputContainer = connect(null, actions)(ErrorStepInput);

export default animationWrapper(ErrorStepInputContainer);
