import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bool, number, obj } from "prop-types";
import { chatHistoryPropType } from "shared/constants/prop-types";
import { DELAY_500 } from "shared/constants/delays";
import { checkinWelcomePath } from "shared/constants/routes";
import scrollIntoViewSmoothly from "shared/utils/smoothScrollIntoView";
import { appDealershipSlugSelector } from "store/selectors/app-selectors";
import {
  chatHistorySelector,
  chatIsInitializedSelector,
  chatLoadingSelector,
  chatLoadingStateDelaySelector,
} from "store/selectors/chat-selectors";
import InputBar from "components/common/InputBar";
import LoadingState from "components/common/LoadingState";
import styles from "./styles.module.scss";

class ChatPage extends Component {
  componentDidUpdate() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.scrollTimeout = setTimeout(() => {
        scrollIntoViewSmoothly(this.messagesEnd, {
          behavior: "smooth",
          block: "end",
        });
        window.scroll(
          0,
          document.getElementById("customer_pages_root").scrollHeight,
        );
      }, 50);
    }
  };

  render() {
    if (!this.props.isInitialized) {
      return <Redirect to={checkinWelcomePath(this.props.dealershipSlug)} />;
    }
    return (
      <Fragment>
        <div className={styles.container}>
          {this.props.history.map(({ component, props, id }) => {
            const ChatComponent = component;
            return (
              <ChatComponent
                key={id}
                {...props}
              />
            );
          })}
          {this.props.isLoading && (
            <LoadingState delay={this.props.loadingStateDelay} />
          )}
          <div
            className={styles.lastMessage}
            ref={(el) => {
              this.messagesEnd = el;
            }}
          />
        </div>
        <InputBar />
      </Fragment>
    );
  }
}

ChatPage.propTypes = {
  isInitialized: bool,
  history: chatHistoryPropType.isRequired || obj,
  isLoading: bool,
  loadingStateDelay: number,
};

ChatPage.defaultProps = {
  isInitialized: false,
  isLoading: false,
  loadingStateDelay: DELAY_500,
};

const mapStateToProps = (state) => ({
  dealershipSlug: appDealershipSlugSelector(state),
  isInitialized: chatIsInitializedSelector(state),
  history: chatHistorySelector(state),
  isLoading: chatLoadingSelector(state),
  loadingStateDelay: chatLoadingStateDelaySelector(state),
});

const ChatPageContainer = connect(mapStateToProps)(ChatPage);

export default ChatPageContainer;
