import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bool, func } from "prop-types";
import { servicesPropType, vehiclePropType } from "shared/constants/prop-types";
import { DELAY_1000, DELAY_1500, DELAY_2000 } from "shared/constants/delays";
import { toggleExtension } from "store/actions/chat-actions";
import {
  chatAdditionalServicesSelector,
  chatCurrentVehicleSelector,
  chatSelectedExtensionsSelector,
} from "store/selectors/chat-selectors";
import AnimatedTextMessage from "components/common/AnimatedTextMessage";
import Animation from "components/common/Animation";
import AnimationGroup from "components/common/AnimationGroup";
import ExtensionService from "components/common/ExtensionService";
import { SOURCE_CONCIERGE, SOURCE_USER } from "components/common/TextMessage";
import styles from "./styles.module.scss";

const MAX_VISIBLE_EXTENSIONS = 3;

const AdditionalServicesStep = ({
  isComplete,
  currentVehicle,
  onToggle,
  additionalServices,
  selectedServices,
}) => (
  <Fragment>
    <AnimationGroup>
      <AnimatedTextMessage
        delay={DELAY_1000}
        message={`I can recommend some additional services based on what other ${currentVehicle.model} owners choose for their cars:`}
        source={SOURCE_CONCIERGE}
        isOpening
      />
      <AnimatedTextMessage
        delay={DELAY_1500}
        message="Feel free to add them to booking!"
        source={SOURCE_CONCIERGE}
      />
      <Animation delay={DELAY_2000}>
        <div className={styles.services}>
          {additionalServices
            .slice(0, MAX_VISIBLE_EXTENSIONS)
            .map((service) => (
              <ExtensionService
                key={service.id}
                service={service}
                onClick={isComplete ? () => null : () => onToggle(service)}
                isHighlighted={
                  !!selectedServices.find(({ id }) => service.id === id)
                }
              />
            ))}
        </div>
      </Animation>
    </AnimationGroup>
    {isComplete && (
      <AnimationGroup>
        <AnimatedTextMessage
          message={
            selectedServices.length
              ? `Let's add this to the list: ${selectedServices
                  .map((s) => s.name)
                  .join(", ")}`
              : "No, thank you."
          }
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

AdditionalServicesStep.propTypes = {
  isComplete: bool,
  currentVehicle: vehiclePropType.isRequired,
  additionalServices: servicesPropType.isRequired,
  selectedServices: servicesPropType,
  onToggle: func.isRequired,
};

AdditionalServicesStep.defaultProps = {
  isComplete: false,
  selectedServices: [],
};

const mapStateToProps = (state) => ({
  currentVehicle: chatCurrentVehicleSelector(state),
  additionalServices: chatAdditionalServicesSelector(state),
  selectedServices: chatSelectedExtensionsSelector(state),
});

const actions = {
  onToggle: toggleExtension,
};

const AdditionalServicesStepContainer = connect(
  mapStateToProps,
  actions,
)(AdditionalServicesStep);

export default AdditionalServicesStepContainer;
