import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import uniqid from "uniqid";
import { addQuestionnairesAnswers } from "store/actions/chat-actions";
import { 
  appDealershipIdSelector,
  appDealershipRemoteEnabledSelector, 
} from "store/selectors/app-selectors";
import {
  chatPhoneNumberSelector,
  chatQuestionnaires,
} from "store/selectors/chat-selectors.js";
import Button from "components/common/Button/Button";
import DrawableCanvas from "components/common/DrawableCanvas";
import bell from "assets/icons/bell.svg";
import close from "assets/icons/close.svg";
import styles from "./styles.module.scss";

const Questionnaires = ({
  showQuestions,
  setShowQuestions,
  questionnaires,
  dealershipId,
  customerPhone,
  remoteEnabled,
  save,
}) => {
  const containerRef = createRef();
  const [questionnairesAnswers, setQuestionnairesAnswers] = useState([]);
  const [stopIndex, setStopIndex] = useState(null);
  const [isCanvasDirty, setIsCanvasDirty] = useState(false);
  const [signature, setSignature] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      if (!canvasWidth) {
        setCanvasWidth(containerRef.current.getBoundingClientRect().width - 32);
      }

      const elementsAmount = containerRef.current.querySelectorAll(
        `.${styles.question}`,
      ).length;
      const answersAmount = questionnairesAnswers.filter(
        (answer) => typeof answer === "string" && /\S/.test(answer),
      ).length;

      setFormCompleted(
        elementsAmount === answersAmount && canvasWidth && !stopIndex,
      );
    }
  }, [containerRef, stopIndex, questionnairesAnswers]);

  useEffect(() => {
    setQuestionnairesAnswers(prepareAnswers());
  }, [questionnaires]);

  const prepareAnswers = () => {
    const answers = new Array(questionnaires.length).fill(null);

    questionnaires.forEach((item, index) => {
      if (item.default) {
        answers[index] = item.default;
      }
    });

    return answers;
  };

  const reset = () => {
    setQuestionnairesAnswers(prepareAnswers());
    setStopIndex(null);
    setIsCanvasDirty(null);
    setSignature(null);
    setShowQuestions(false);
  };

  const onUpdateSignature = (value) => {
    setSignature(value);
  };

  const handleClearClick = () => {
    setIsCanvasDirty(false);
    setSignature(null);
    window.clearSignature();
  };

  const buttonComponent = (index, value, stop) => {
    const handleChange = (value) => {
      const result = [...questionnairesAnswers];

      setStopIndex(stop === value && result[index] !== value ? index : null);

      if (result[index] !== value) {
        result[index] = value;
      } else {
        result[index] = null;
      }

      for (let i = index + 1; i < result.length; i++) {
        if ("visibility" in questionnaires[i]) {
          if (questionnaires[i].visibility.index === index) {
            result[i] = null;
          }
        }
      }

      setQuestionnairesAnswers(result);
    };

    const bgColor = () => {
      if (questionnairesAnswers[index] === value) {
        if (questionnairesAnswers[index] === stop) {
          return "rgba(254, 62, 127, 0.13)";
        }
        return "rgba(11, 202, 249, 0.1)";
      }

      return "#FFFFFF";
    };

    const border = () => {
      if (questionnairesAnswers[index] === value) {
        if (questionnairesAnswers[index] === stop) {
          return "1px solid #FE3E7F";
        }
        return "1px solid #0BCAF9";
      }

      return "1px solid #FFFFFF";
    };

    const color = () => {
      if (questionnairesAnswers[index] === value) {
        if (questionnairesAnswers[index] === stop) {
          return "#FE3E7F";
        }
        return "#0BCAF9";
      }

      return "#2E3042";
    };

    return (
      <Button
        color={color()}
        bgColor={bgColor()}
        border={border()}
        text={value}
        onClick={() => handleChange(value)}
        isWide
      />
    );
  };

  const label = (question) => {
    if (question.indexOf("terms and conditions") !== -1) {
      const parts = question.split("terms and conditions");

      return (
        <p>
          {parts[0]}
          <a href="https://getcarmen.com/mobile-service-tc/">
            terms and conditions
          </a>
          {parts[1]}
        </p>
      );
    }

    return <p>{question}</p>;
  };

  return (
    <div
      ref={containerRef}
      className={cx(styles.questionsContainer, {
        [styles.questionsContainerOpen]: showQuestions,
      })}
    >
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>Mobile service questionnaire</p>
          <p className={styles.subtitle}>
            Complete the questionnaire to confirm your appointment.
          </p>
        </div>
        <div
          className={styles.close}
          onClick={reset}
        >
          <img
            src={close}
            alt=""
          />
        </div>
      </div>
      <div className={styles.body}>
        {questionnaires.map((item, index) => {
          if (stopIndex && stopIndex < index) {
            return null;
          }

          let showItem = false;

          if ("visibility" in item) {
            if (
              questionnairesAnswers[item.visibility.index] ===
              item.visibility.value
            ) {
              showItem = true;
            }
          } else {
            showItem = true;
          }

          if (showItem) {
            if (item.type === "bool") {
              return (
                <div
                  key={uniqid()}
                  className={styles.question}
                >
                  {label(item.question)}
                  <div className={styles.questionTypeBool}>
                    {buttonComponent(index, item.variants[0], item.stop)}
                    {buttonComponent(index, item.variants[1], item.stop)}
                  </div>
                </div>
              );
            }

            if (item.type === "string") {
              const handleChange = (event) => {
                const result = [...questionnairesAnswers];
                result[index] = event.target.value;

                setQuestionnairesAnswers(result);
              };

              return (
                <div
                  key={`questionaries-input-${index}`}
                  className={styles.question}
                >
                  <p>{item.question}</p>
                  <div className={styles.questionTypeString}>
                    <input
                      type="text"
                      value={questionnairesAnswers[index] || ""}
                      onClick={(event) => event.stopPropagation(event)}
                      onKeyUp={(event) => event.preventDefault()}
                      onChange={(event) => handleChange(event)}
                      maxLength={200}
                    />
                  </div>
                </div>
              );
            }
          }

          return null;
        })}
        {stopIndex && (
          <div className={styles.errorContainer}>
            <div className={styles.icon}>
              <img
                src={bell}
                alt=""
              />
            </div>
            <div className={styles.error}>
              {`Because of your answer, we are unable to offer mobile service. 
                We can offer ${remoteEnabled ? 'pick up and delivery or' : ''} 
                service at the dealership.  Current appointment will be 
                automatically cancelled.`}
            </div>
          </div>
        )}
        {formCompleted && (
          <>
            <div className={styles.canvasWrapper}>
              {!isCanvasDirty && (
                <div className={styles.placeholder}>
                  Draw your signature here
                </div>
              )}
              {isCanvasDirty && (
                <div
                  className={styles.clearBtn}
                  onClick={handleClearClick}
                >
                  Clear
                </div>
              )}
              <DrawableCanvas
                width={canvasWidth}
                height={Math.min(canvasWidth, 300)}
                lineWidth={2}
                onUpdateContent={onUpdateSignature}
                onDrawStart={() => setIsCanvasDirty(true)}
              />
            </div>
            <Button
              color={"#FFFFFF"}
              bgColor={"#2E3042"}
              text={"Complete"}
              onClick={() => {
                const answers = {};

                questionnairesAnswers.forEach((answer, index) => {
                  if (answer) {
                    answers[questionnaires[index].question] = answer;
                  }
                });

                save(answers, signature);
                setShowQuestions(false);
              }}
              disabled={!signature}
              isWide
            />
          </>
        )}
        {stopIndex && (
          <Button
            color={"#FFFFFF"}
            bgColor={"#2E3042"}
            text={"Schedule a new appointment"}
            onClick={() =>
              (window.location.href = `https://reserve.getcarmen.com/?dealershipId=${dealershipId}&phone=${customerPhone}`)
            }
            isWide
          />
        )}
      </div>
    </div>
  );
};

const actions = {
  save: addQuestionnairesAnswers,
};

const mapStateToProps = (state) => ({
  questionnaires: chatQuestionnaires(state),
  dealershipId: appDealershipIdSelector(state),
  customerPhone: chatPhoneNumberSelector(state),
  remoteEnabled: appDealershipRemoteEnabledSelector(state),
});

export default connect(mapStateToProps, actions)(Questionnaires);
